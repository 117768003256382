$primary: #0010f1;
$secondary: #161616;
$common-text: #969696;
$gray: #f8f8f8;
$blue: #0010f1;

body {
  @media (max-width: 991px) {
    overflow-x: hidden;
  }
}

body {
  font-family: 'Inter', sans-serif !important;
}

:root {
  --horizontal-margin: 165px;

  @media (max-width: 1600px) {
    --horizontal-margin: 120px;
  }

  @media (max-width: 1400px) {
    --horizontal-margin: 100px;
  }

  @media (max-width: 1200px) {
    --horizontal-margin: 60px;
  }

  @media (max-width: 991px) {
    --horizontal-margin: 40px;
  }
}

@mixin button($bcolor, $url, $x1, $y1, $bor, $col) {
  background: $bcolor;
  -webkit-mask: url($url);
  mask: url($url);
  -webkit-mask-size: $x1 $y1;
  mask-size: $x1 $y1;
  border: $bor;
  color: $col;
}

.new_landing_page {
  .custom_btn_wrap {
    position: relative;
    width: max-content;
    background: #000;
    border-radius: 10px;

    .mas {
      position: absolute;
      color: #fff;
      text-align: center;
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      padding: 12px 20px;
      height: 48px;
      line-height: 22px;
      overflow: hidden;
      border: 1px solid #666;
      border-radius: 10px;
    }
  }

  .mas {
    position: absolute;
    color: #fff;
    text-align: center;
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    padding: 0 32px;
    height: 60px;
    line-height: 58px;
    overflow: hidden;
    border: 1px solid #666;
    border-radius: 100px;

    @media (max-width: 991px) {
      height: 50px;
      line-height: 50px;
    }
  }

  .btn_primary,
  .inner_btn_primary {
    border: none;
    background: $blue;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 10px;
    padding: 14px 24px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    @include button($primary,
      '../../public/assets/images/ecommerce-development-company/nature-sprite.png',
      2300%,
      100%,
      none,
      #fff);
    cursor: pointer;
    -webkit-animation: ani2 0.7s steps(22) forwards;
    animation: ani2 0.7s steps(22) forwards;

    &:hover {
      -webkit-animation: ani 0.7s steps(22) forwards;
      animation: ani 0.7s steps(22) forwards;
    }
  }

  .header_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: $secondary url(../../public/assets/images/ecommerce-development-company/bg-lines.png) top center no-repeat;

    @media only screen and (max-width: 991px) {
      height: 70vh;
    }
  }

  .container {
    max-width: 1600px !important;
    margin: 0 auto;
  }

  .col_gap_30 {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 30px;
  }

  p {
    font-size: 18px;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    line-height: 24px;
    color: #000;
    font-weight: 400;
    margin-bottom: 0;

    @media only screen and (max-width: 991px) {
      font-size: 16px;
    }
  }

  .custom_btn {
    background: $primary;
    border-radius: 100px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    padding: 0 38px;
    height: 60px;
    border: none;
    @include button($primary,
      '../../public/assets/images/ecommerce-development-company/nature-sprite.png',
      2300%,
      100%,
      none,
      #fff);
    cursor: pointer;
    -webkit-animation: ani2 0.7s steps(22) forwards;
    animation: ani2 0.7s steps(22) forwards;

    &:hover {
      -webkit-animation: ani 0.7s steps(22) forwards;
      animation: ani 0.7s steps(22) forwards;
    }

    @keyframes ani {
      from {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
      }

      to {
        -webkit-mask-position: 100% 0;
        mask-position: 100% 0;
      }
    }

    @-webkit-keyframes ani2 {
      from {
        -webkit-mask-position: 100% 0;
        mask-position: 100% 0;
      }

      to {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
      }
    }

    @keyframes ani2 {
      from {
        -webkit-mask-position: 100% 0;
        mask-position: 100% 0;
      }

      to {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
      }
    }

    @media only screen and (max-width: 991px) {
      font-size: 16px;
      font-weight: 700;
      padding: 0 38px;
      height: 50px;
      line-height: 50px !important;
    }
  }

  .dark_section {
    background: $secondary;

    .section_titles {
      .title {
        color: #fff;
      }

      .sub_title {
        color: #fff;
        background: $secondary;
        border: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }

  .section_space {
    padding: 120px 165px;
    padding: calc(60px + (120 - 60) * ((100vw - 320px) / (1920 - 320))) calc(15px + (165 - 15) * ((100vw - 320px) / (1920 - 300)));

    // @media only screen and (max-width: 1440px) {
    //   padding: 80px 0;
    // }

    // @media only screen and (max-width: 991px) {
    //   padding: 60px 0;
    // }
  }

  .section_titles {
    padding-bottom: 70px;
    text-align: center;

    @media only screen and (max-width: 1440px) {
      padding-bottom: 40px;
    }

    &.inner_title {
      padding-bottom: 0;

      .title {
        font-size: 40px;
        line-height: 48px;

        @media only screen and (max-width: 991px) {
          font-size: 30px;
          line-height: 1.35;
        }
      }
    }

    .title,
    .inner_title {
      font-size: 50px;
      line-height: 60px;
      color: #000;
      font-weight: 700 !important;
      max-width: 1200px;
      margin: 0 auto;

      &.left_title {
        max-width: 700px;
        margin: unset;
      }

      @media only screen and (max-width: 1440px) {
        font-size: 40px;
        line-height: 1.35;

        br {
          display: none;
        }
      }

      @media only screen and (max-width: 991px) {
        font-size: 30px;
      }
    }

    .sub_title,
    .inner_sub_title {
      color: #000;
      background: #fff;
      border: 1px solid rgba(#000, 0.1);
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      backdrop-filter: blur(2px);
      padding: 10px 10px 10px 30px;
      border-radius: 10px;
      display: inline-block;
      position: relative;
      margin-bottom: 20px;

      @media only screen and (max-width: 991px) {
        font-size: 16px;
      }

      &::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: $primary;
        border-radius: 100%;
        left: 10px;
        top: 15px;
      }
    }

    .sub_text {
      max-width: 1000px;
      width: 100%;
      margin: auto;
      color: #fff;
      margin-top: 20px;
    }
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #000;
    border-radius: 100px;
    margin-top: 40px;
    height: 80px;
    padding: 10px;
    position: sticky;
    z-index: 9;
    max-width: 1600px;
    top: 30px;
    margin: 0 auto;
    width: calc(100% - 30px);
    z-index: 99999;

    @media only screen and (max-width: 991px) {
      top: 10px;
      height: 60px;
    }

    @media only screen and (max-width: 475px) {
      top: 5px;
    }

    .logo {
      margin-left: 20px;

      @media only screen and (max-width: 991px) {
        margin-left: 5px;
        padding-top: 8px;
      }

      img {
        filter: grayscale(1) brightness(10);
      }
    }

    .navbar {
      width: 100%;

      .container {
        padding-top: 12px;
        padding-bottom: 12px;

        .navbar-brand {
          img {
            height: 64px;

            @media only screen and (max-width: 991px) {
              height: 54px;
            }
          }
        }

        .navbar-toggler-icon {
          filter: invert(1) brightness(5);
        }

        .navbar-collapse {
          flex-grow: unset;
          margin-right: -10px;

          @media only screen and (max-width: 991px) {
            position: absolute;
            right: -22px;
            top: 60px;
            padding: 20px;
            border-radius: 20px;
            background: #000;
            z-index: 9;
            margin-right: 0;
          }
        }

        .navbar-toggler {
          border: none;
          padding: 0;
        }

        .navbar-nav {
          align-items: center;

          a.nav-link {
            font-size: 18px;
            font-weight: 400;
            margin-right: 50px;
            margin-right: calc(30px + (50 - 30) * ((100vw - 992px) / (1920 - 992)));
            color: #fff;

            @media only screen and (max-width: 991px) {
              margin-right: 0;
            }

            &:hover,
            &.current {
              color: #ccc;
            }
          }
        }

        .right_links_wrap {
          margin-left: auto;

          @media only screen and (max-width: 991px) {
            margin-top: 20px;
          }

          .right_links {
            position: relative;

            a {
              display: inline-block;
              margin-right: 20px;
              line-height: 60px;
              text-decoration: none;
            }
          }
        }
      }
    }

    .menus {
      nav {
        ul {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          list-style: none;

          li {
            margin-right: 50px;

            a {
              color: #fff;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .hero_section {
    margin-top: 120px;

    @media only screen and (max-width: 991px) {
      margin-top: 60px;
    }

    .section_titles {
      margin-top: 70px;

      .title {
        font-size: 64px;
        line-height: 80px;

        @media only screen and (max-width: 1440px) {
          font-size: 50px;
          line-height: 1.35;
        }

        @media only screen and (max-width: 991px) {
          font-size: 30px;
        }
      }
    }

    .hero_slider {
      margin-top: 40px;
      padding-bottom: 120px;

      @media only screen and (max-width: 1550px) {
        padding-bottom: 120px;
      }

      @media only screen and (max-width: 991px) {
        padding-bottom: 140px;
      }

      .slick-prev {
        left: 20px;
        transform: rotate(180deg);

        @media only screen and (max-width: 991px) {
          left: unset;
          right: 130px;
        }
      }

      .slick-next {
        left: 130px;
        transform: unset;

        @media only screen and (max-width: 991px) {
          left: unset;
          right: 20px;
        }
      }

      .slick-prev,
      .slick-next {
        width: 70px;
        height: 23px;
        bottom: -60px;
        top: unset;
        z-index: 99;
        background: url(../../public/assets/images/ecommerce-development-company/icons/slider-arrow.svg) no-repeat;

        @media only screen and (max-width: 1550px) {
          bottom: -70px;
        }

        &::before {
          content: '';
        }

        &.slick-disabled {
          opacity: 0.3;
        }
      }

      .slide_item {
        padding: 0 20px;

        .row {
          --bs-gutter-x: 165px;
          font-size: 32px;
          font-weight: 700;
          line-height: 38px;
          color: #000;
          font-weight: 700;

          @media only screen and (max-width: 1440px) {
            --bs-gutter-x: 75px;
          }

          @media only screen and (max-width: 1250px) {
            --bs-gutter-x: 45px;
          }

          h2 {
            font-size: 40px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 30px;

            @media only screen and (max-width: 1440px) {
              font-size: 35px;
            }

            @media only screen and (max-width: 991px) {
              font-size: 30px;
              margin-bottom: 20px;
            }
          }

          p {
            font-size: 20px;
            line-height: 32px;
            color: #fff;
            text-align: justify;

            @media only screen and (max-width: 1440px) {
              font-size: 18px;
              line-height: 28px;
            }

            @media only screen and (max-width: 991px) {
              font-size: 16px;
              line-height: 28px;
            }
          }

          .slide_img {
            @media only screen and (max-width: 991px) {
              margin-top: 30px;
            }
          }
        }

        &:active,
        &:focus {
          outline: none;
          box-shadow: none;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  .partners_section {
    .partners_block {
      border-radius: 20px;
      background: rgba(0, 178, 255, 0.05);
      border: 0.5px solid rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      @media only screen and (max-width: 1280px) {
        margin-bottom: 30px;
      }

      @media only screen and (max-width: 991px) {
        text-align: center;
        height: auto;
      }

      img {
        width: 100%;
      }
    }

    .text_content {
      padding: 30px;

      h3 {
        font-size: 76px;
        line-height: 92px;
        color: #000;
        font-weight: 700;
        margin-bottom: 15px;

        @media only screen and (max-width: 1440px) {
          font-size: 60px;
          line-height: 1.2;
        }

        @media only screen and (max-width: 991px) {
          font-size: 45px;
        }
      }
    }
  }

  .services_section {
    .blue_bg {
      background: url(../../public/assets/images/ecommerce-development-company/blue-bg.png) center center no-repeat;
    }

    .row {
      [class*='col'] {
        &:nth-child(1) {
          .service_block {
            &:hover {
              background: #ec6737 url(../../public/assets/images/ecommerce-development-company/frameworks/magento.png) center right no-repeat;
            }
          }
        }

        &:nth-child(2) {
          .service_block {
            &:hover {
              background: #95bf46 url(../../public/assets/images/ecommerce-development-company/frameworks/shopify.png) center right no-repeat;
            }
          }
        }

        &:nth-child(3) {
          .service_block {
            &:hover {
              background: #009dde url(../../public/assets/images/ecommerce-development-company/frameworks/wocomm.png) center right no-repeat;
            }
          }
        }

        &:nth-child(4) {
          .service_block {
            &:hover {
              background: #3ecaf0 url(../../public/assets/images/ecommerce-development-company/frameworks/opencart.png) center right no-repeat;
            }
          }
        }

        &:nth-child(5) {
          .service_block {
            &:hover {
              background: #9b5c8f url(../../public/assets/images/ecommerce-development-company/frameworks/prestashop.png) center right no-repeat;
            }
          }
        }

        &:nth-child(6) {
          .service_block {
            &:hover {
              background: #a5dbe8 url(../../public/assets/images/ecommerce-development-company/frameworks/drupal.png) center right no-repeat;
            }
          }
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          .service_block {
            border-radius: 20px 20px 0px 0px;

            @media only screen and (max-width: 991px) {
              border-radius: 15px;
            }
          }
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          .service_block {
            border-radius: 0 0 20px 20px;

            @media only screen and (max-width: 991px) {
              border-radius: 15px;
            }
          }
        }
      }
    }

    .service_block {
      background: #1f1f1f;
      border: 0.5px solid rgba(255, 255, 255, 0.5);
      padding: 50px;
      padding: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
      transition: background-color 0.3s linear;
      height: 100%;

      &:hover {
        border: 0.5px solid transparent;
      }

      @media only screen and (max-width: 1440px) {
        padding: 40px;
      }

      @media only screen and (max-width: 991px) {
        padding: 30px;
      }

      h3 {
        font-size: 24px;
        line-height: 34px;
        color: #fff;
        font-weight: 700;
        margin-bottom: 30px;

        @media only screen and (max-width: 1440px) {
          margin-bottom: 20px;
        }
      }

      p {
        font-size: 22px;
        font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
        line-height: 34px;
        line-height: calc(28px + (34 - 28) * ((100vw - 320px) / (1920 - 320)));
        color: #fff;

        @media only screen and (max-width: 1440px) {
          // font-size: calc(22px - 0.390625vw);
        }
      }
    }
  }

  .tech_services_section {
    .tech_service_block {
      background: #fff;
      border: 1px solid #ececec;
      padding: 30px;
      border-radius: 20px;
      gap: 30px;
      margin-bottom: 30px;
      height: 100%;
      transition: all 0.3s ease-in;

      &:hover {
        border: 1px solid transparent;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        background: $blue;

        h3,
        p {
          color: #fff;
        }
      }

      h3 {
        font-size: 30px;
        font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
        line-height: 42px;
        line-height: calc(32px + (42 - 32) * ((100vw - 320px) / (1920 - 320)));
        color: #000;
        font-weight: 700;
        margin-bottom: 20px;

        @media only screen and (max-width: 1650px) {
          height: 125px;
        }

        @media only screen and (max-width: 1200px) {
          height: 75px;
        }

        @media only screen and (max-width: 991px) {
          height: auto;

          br {
            display: none;
          }
        }
      }

      p {}
    }
  }

  .dedicated_developer_wrap {
    background: $secondary;
    display: flex;
    justify-content: flex-end;
    margin-top: -1px;

    &.left_align {
      background: #262626;
      justify-content: start;

      .dedicated_developer {
        width: 80%;
        border-radius: 0 20px 20px 0;
        padding: 65px 0 65px 0px;
        box-shadow: -40vw 0 0 #000;
        margin-left: unset;

        @media only screen and (max-width: 1650px) {
          padding: 65px 490px 65px 50px;
        }

        @media only screen and (max-width: 991px) {
          width: 95%;
          padding: 50px;
          display: flex;
          flex-direction: column-reverse;
        }

        .section_img {
          left: unset;
          right: -50px;

          @media only screen and (max-width: 1650px) {}
        }

        p {
          max-width: 550px;
        }
      }
    }

    .dedicated_developer {
      width: 70%;
      background: #000 url(../../public/assets/images/ecommerce-development-company/bg-lines.png) top center no-repeat;
      border-radius: 20px 0 0 20px;
      margin-top: 120px;
      margin-bottom: 120px;
      padding: 95px 0 95px 400px;
      position: relative;
      box-shadow: 40vw 0 0 #000;
      margin-left: auto;

      @media only screen and (max-width: 1650px) {
        margin-top: 80px;
        margin-bottom: 80px;
        padding: 95px 50px 95px 270px;
      }

      @media only screen and (max-width: 991px) {
        width: 90%;
        margin-top: 60px;
        margin-bottom: 60px;
        padding: 50px;
      }

      .section_img {
        margin-bottom: 30px;

        @media only screen and (min-width: 992px) {
          margin-bottom: 0;
          position: absolute;
          left: -270px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .section_titles {
        max-width: 780px;
        width: 100%;
        text-align: left;
        padding-bottom: 0;

        .sub_title,
        .inner_sub_title {
          font-size: 14px;
          line-height: 20px;
          background: $secondary !important;
          border: 1px solid rgba(255, 255, 255, 0.1);
        }

        .title,
        .inner_title {
          color: #fff;
          font-size: 40px;
          line-height: 48px;
          margin-bottom: 18px;

          @media (max-width: 1440px) {
            font-size: 35px;
            line-height: 1.35;
          }

          @media (max-width: 991px) {
            font-size: 30px;
          }
        }

        p {
          font-size: 20px;
          line-height: 28px;
          color: #fff;

          @media (max-width: 1440px) {
            font-size: 18px;
            line-height: 1.35;
          }

          @media (max-width: 991px) {
            font-size: 16px;
          }
        }

        .btn_primary,
        .inner_btn_primary,
        .mas {
          display: inline-block;
          margin-top: 30px;
        }
      }
    }
  }

  .multiple_ways_sell_section {
    .sell_block_wrap {
      .bid_here_wrap {
        .sell_block {
          .text_content {
            padding-right: 60px;
          }
        }
      }

      .sell_block {
        background: #343434;
        border-radius: 20px;
        display: flex;
        align-items: center;
        height: 520px;
        border: 0.5px solid;
        position: relative;
        background: linear-gradient(#343434, #343434) padding-box,
          linear-gradient(to right, #333, #999) border-box;
        border: 1px solid transparent;

        @media only screen and (max-width: 1200px) {
          flex-direction: column;
          height: auto;
        }

        .social_links {
          display: flex;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $blue;
            box-shadow: 0px -7px 10px rgba(75, 75, 75, 0.01),
              0px 7px 10px rgba(75, 75, 75, 0.1);
            border-radius: 100%;
            width: 85px;
            height: 85px;
            margin: 44px calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320))) 0;

            @media only screen and (max-width: 1700px) {
              width: 65px;
              height: 65px;
            }

            @media only screen and (max-width: 1440px) {
              width: 55px;
              height: 55px;
            }

            &:first-child,
            &:last-child {
              margin-top: 0;
            }
          }
        }

        .bid_here {
          border-radius: 0px 50px 50px 0px;
          max-width: 80%;
          width: 100%;
          height: calc(60px + (90 - 60) * ((100vw - 320px) / (1920 - 320)));
          line-height: 90px;
          padding-left: calc(30px + (75 - 30) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 700;
          font-size: 28px;
          font-size: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
          line-height: 34px;
          line-height: calc(28px + (34 - 28) * ((100vw - 320px) / (1920 - 320)));
          color: rgba(#fff, 0.5);
          display: flex;
          align-items: center;
          margin-top: 70px;
          // border: 1px solid;
          background: $secondary;
          position: relative;
          background: linear-gradient($secondary, $secondary) padding-box,
            linear-gradient(to right, $secondary, #ccc) border-box;
          border: 1px solid transparent;

          @media only screen and (max-width: 1600px) {
            margin-top: 30px;
          }

          @media only screen and (max-width: 1200px) {
            max-width: 300px;
            margin-top: 50px;
          }

          img {
            margin-right: 20px;
          }
        }

        .sell_block_img {
          img {
            border-radius: 20px;
          }
        }

        &.horizontal_block {
          .text_content {
            width: 55%;

            @media only screen and (max-width: 1200px) {
              width: 100%;
            }
          }

          .sell_block_img {
            width: 45%;
            height: 100%;

            @media only screen and (max-width: 1200px) {
              width: 100%;
            }

            img {
              height: 100%;
              object-fit: cover;

              @media only screen and (max-width: 1200px) {
                width: 100%;
              }
            }
          }
        }

        &.vertical_block {
          flex-direction: column;
          justify-content: space-between;

          .text_content {
            width: 100%;
            padding-bottom: 30px;
          }

          .sell_block_img {
            width: 100%;

            img {
              width: 100%;
            }
          }
        }

        .sell_block_img {
          padding: 5px;
        }

        .text_content {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          padding: 40px;
          padding: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));

          .title {
            font-size: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
            line-height: 33px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 15px;
          }

          p {
            font-size: 20px;
            line-height: 32px;
            color: #fff;
            // height: 170px;
            // @extend .custom_scrollbar;

            @media only screen and (max-width: 1600px) {
              font-size: 18px;
              line-height: 1.5;
            }

            @media only screen and (max-width: 1440px) {
              font-size: 16px;
            }

            // @media only screen and (max-width:1200px) {
            //   height: auto;
            // }
          }
        }
      }
    }
  }

  .process_section {
    .nav-tabs {
      overflow: hidden;
      border-bottom: none;
      display: flex;
      justify-content: center;

      @media (max-width: 1440px) {
        justify-content: flex-start;
      }

      .slick-list {
        padding-left: 10px;

        .slick-track {
          @media (min-width: 1400px) {
            width: 100% !important;
            transform: none !important;
          }

          .slick-slide {
            margin-right: 40px;

            @media (max-width: 1600px) {
              margin-right: 25px;
            }

            &.slick-current {
              border-bottom: 3px solid #000;

              .nav-link {
                font-weight: 500;
                color: #000;
                border: none;

                @media (max-width: 1400px) {
                  font-size: 18px;
                }

                @media (max-width: 991px) {
                  font-size: 17px;
                }
              }
            }
          }
        }
      }

      .nav-item {
        cursor: pointer;
        padding-bottom: 10px;

        .nav-link {
          padding: 0;

          font-size: 20px;
          color: #b1b1b1;
          background: transparent;
          border: none;
          line-height: 1.35;

          @media screen and (max-width: 1440px) {
            font-size: 18px;
          }

          @media (max-width: 991px) {
            font-size: 17px;
          }

          &:hover {
            border-color: transparent;
          }
        }
      }
    }

    .process_carousel {
      margin-top: 80px;

      @media screen and (max-width: 1440px) {
        margin-top: 60px;
      }

      @media screen and (max-width: 991px) {
        margin-top: 40px;
      }
    }

    .tab-content {
      .row {
        align-items: center;
      }

      .left_content {
        margin: 0 90px 50px 200px;

        @media screen and (max-width: 1440px) {
          margin: 50px 0 100px 50px;
        }

        @media screen and (max-width: 991px) {
          margin: 30px 0 0 50px;
        }

        @media screen and (max-width: 767px) {
          margin: 30px 0 0 0;
        }

        .title {
          font-size: 28px;
          font-weight: 700;
          line-height: 33px;
          color: #000;

          @media screen and (max-width: 1440px) {
            font-size: 24px;
          }

          @media screen and (max-width: 991px) {
            font-size: 22px;
          }
        }

        p {
          color: #000;
          font-size: 22px;
          font-weight: 400;
          margin-top: 16px;
          margin-right: 100px;
          line-height: 30px;
          @extend .custom_scrollbar;

          @media screen and (max-width: 1440px) {
            font-size: 20px;
            margin-right: 50px;
            line-height: 1.35;
          }

          @media screen and (max-width: 991px) {
            font-size: 18px;
          }

          @media screen and (max-width: 767px) {
            height: 170px;
            margin-right: 0;
          }
        }

        ul {
          padding-left: 20px;

          li {
            font-size: 24px;
            line-height: 42px;
            font-weight: 600;
            margin-left: 6px;

            @media screen and (max-width: 991px) {
              font-size: 20px;
              line-height: 36px;
            }
          }
        }
      }

      .right_content {
        display: flex;
        justify-content: center;

        img {
          height: 460px;

          @media screen and (max-width: 1600px) {
            height: 350px;
          }

          @media screen and (max-width: 1200px) {
            height: 300px;
          }

          @media screen and (max-width: 991px) {
            height: 200px;
          }

          @media screen and (max-width: 767px) {
            height: 250px;
          }
        }

        @media screen and (max-width: 991px) {
          margin-top: 30px;
        }
      }
    }
  }

  .web_services_section {
    background: #262626;

    .section_titles .sub_title {
      background: #262626;
    }

    .services_flow {
      position: relative;
      height: 700px;
      --icon-size: 112px;
      --horizontal-padding: 60px;

      @media screen and (max-width: 1700px) {
        --icon-size: 100px;
      }

      @media screen and (max-width: 1200px) {
        --icon-size: 90px;
        left: 50%;
        transform: translate(-50%);
      }

      @media screen and (max-width: 767px) {
        --icon-size: 70px;
        --horizontal-padding: 0px;
      }

      .dotted-lines-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 0;
        transition: all 2.5s ease;

        .dotted-line {
          position: absolute;
          width: 75%;

          .cls-1 {
            stroke-dasharray: 10;
          }

          .cls-1,
          .cls-2 {
            fill: none;
            stroke: #fff;
            stroke-linecap: round;
            stroke-miterlimit: 10;
            stroke-width: 2px;
          }

          @media (max-width: 1200px) {
            display: none;
          }
        }

        .dotted-line-vertical {
          position: absolute;
          height: calc(100% - var(--icon-size));
          width: 4px;
          top: calc(var(--icon-size) / 2);
          left: calc(var(--horizontal-padding) + var(--icon-size) / 2);
          display: none;

          line {
            stroke: #fff;
            stroke-width: 2px;
            stroke-dasharray: 10;
            stroke-linecap: round;
            height: 100%;
          }

          @media (max-width: 1200px) {
            display: block;
          }
        }
      }

      @media screen and (max-width: 1200px) {
        height: auto;
      }

      .web_services_block {
        display: flex;
        position: absolute;
        background: #262626;

        &:hover {
          img {
            animation: rotate 1s linear;
          }
        }

        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }

          50% {
            transform: rotate(180deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }

        @media screen and (max-width: 1200px) {
          position: relative;
          margin-top: 70px;
          padding: 0 var(--horizontal-padding);
          left: unset !important;
          top: unset !important;
          bottom: unset !important;
          background: transparent;
        }

        .icon {
          background-color: var(--color);
        }

        h3 {
          color: var(--color);
          background: #262626;
        }

        p {
          background: #262626;
        }

        &.yellow {
          left: 7%;
          bottom: 52px;
          --color: #ffd71f;

          &:hover {
            .icon {
              box-shadow: 0 0 0 var(--outline-spacing) $secondary,
                0 0 0 calc(var(--outline-spacing) + 2px) var(--color);
            }
          }
        }

        &.green {
          left: 19%;
          bottom: 43%;
          --color: #00d983;

          &:hover {
            .icon {
              box-shadow: 0 0 0 var(--outline-spacing) $secondary,
                0 0 0 calc(var(--outline-spacing) + 2px) var(--color);
            }
          }

          .web_services_block_text {
            width: 315px;
            top: -170px;
            left: -300px;

            @media screen and (max-width: 1700px) {
              top: -140px;
              left: -270px;
            }

            @media screen and (min-width: 1201px) {
              text-align: right;
              position: absolute;
            }
          }
        }

        &.cyan {
          top: 18px;
          left: 35%;
          --color: #61edec;

          &:hover {
            .icon {
              box-shadow: 0 0 0 var(--outline-spacing) $secondary,
                0 0 0 calc(var(--outline-spacing) + 2px) var(--color);
            }
          }

          @media screen and (max-width: 1440px) {
            top: 70px;
          }

          .icon {
            margin-top: 15px;
          }
        }

        &.red {
          bottom: 37%;
          left: 50%;
          --color: #ff6948;

          &:hover {
            .icon {
              box-shadow: 0 0 0 var(--outline-spacing) $secondary,
                0 0 0 calc(var(--outline-spacing) + 2px) var(--color);
            }
          }

          .icon {
            margin-top: 15px;
          }
        }

        &.blue {
          bottom: 25px;
          left: 68%;
          --color: #7eb2ff;

          &:hover {
            .icon {
              box-shadow: 0 0 0 var(--outline-spacing) $secondary,
                0 0 0 calc(var(--outline-spacing) + 2px) var(--color);
            }
          }

          .icon {
            margin-top: 15px;
          }
        }

        &.orange {
          bottom: 40%;
          left: 85%;
          --color: #ffa52b;

          &:hover {
            .icon {
              box-shadow: 0 0 0 var(--outline-spacing) $secondary,
                0 0 0 calc(var(--outline-spacing) + 2px) var(--color);
            }
          }

          @media screen and (max-width: 1440px) {
            left: 88%;
          }

          .icon {
            margin-top: 15px;
          }

          .web_services_block_text {
            width: 315px;
            top: -180px;
            left: -60px;

            @media screen and (min-width: 1201px) {
              position: absolute;
            }
          }
        }

        .icon {
          --outline-spacing: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: var(--icon-size);
          height: var(--icon-size);
          border-radius: 100%;
          margin-right: 45px;
          margin-right: calc(20px + (45 - 20) * ((100vw - 320px) / (1920 - 320)));
          box-shadow: 0 0 0 var(--outline-spacing) $secondary,
            0 0 0 calc(var(--outline-spacing) + 2px) #fff;
          flex: none;
          transition: all 0.3s ease;

          @media screen and (max-width: 1200px) {
            margin-top: 15px;
          }

          @media screen and (max-width: 767px) {
            --outline-spacing: 8px;
          }

          img {
            transition: all 0.6s ease;

            @media screen and (max-width: 1200px) {
              width: 50px;
              aspect-ratio: 1/1;
            }

            @media screen and (max-width: 767px) {
              width: 40px;
            }
          }
        }

        .web_services_block_text {
          width: 100%;
          max-width: 315px;

          @media screen and (max-width: 1700px) {
            max-width: 255px;
          }

          @media screen and (max-width: 1200px) {
            max-width: 415px;
          }

          h3 {
            font-size: 24px;
            line-height: 34px;
            font-weight: 700;
            margin-bottom: 10px;

            @media screen and (max-width: 1700px) {
              font-size: 20px;
              line-height: 30px;
            }
          }

          p {
            color: #fff;
            opacity: 0.6;

            @media screen and (max-width: 1700px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .our_work_section {
    .work_slider {
      .slick-list {
        max-width: 1400px;
        margin: 0 auto;
        width: 100%;
      }

      .slick-slide {
        padding: 50px;

        @media screen and (max-width: 991px) {
          padding: 20px 10px;
        }

        .slide_item {
          background: #ffffff;
          box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
          border-radius: 20px;

          @media screen and (max-width: 991px) {
            border: 1px solid #eee;
            box-shadow: none;
          }

          img {
            width: 100%;
            border-radius: 20px 20px 0 0;
          }

          .text_content {
            padding: 50px;

            @media screen and (max-width: 991px) {
              padding: 20px;
            }

            h2 {
              font-size: 32px;
              font-weight: 700;
              line-height: 38px;
              color: #000;
              font-weight: 700;
              margin-bottom: 16px;
            }

            p {
              height: 195px;
              @extend .custom_scrollbar;
            }
          }
        }
      }

      .slick-dots li {
        &.slick-active {
          width: 50px;

          button {
            width: 50px;
            background: $blue;
          }
        }

        button {
          width: 15px;
          height: 15px;
          background: #d0d0d0;
          border-radius: 20px;
        }
      }
    }
  }

  .solutions_section {
    .solutions_data {
      max-width: 1320px;
      width: 100%;
      margin: 0 auto;
      background: #fff;
      border-radius: 20px 20px 0 0;
      padding: 120px;
      display: flex;
      flex-wrap: wrap;
      row-gap: 60px;
      justify-content: space-around;

      @media screen and (max-width: 1440px) {
        padding: 80px;
      }

      @media screen and (max-width: 991px) {
        row-gap: 40px;
        padding: 40px;
      }

      @media screen and (max-width: 475px) {
        padding: 40px 20px;
      }

      .solution_block {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25%;

        @media screen and (max-width: 1440px) {
          width: 33.33%;
        }

        @media screen and (max-width: 991px) {
          width: 50%;
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 105px;
          height: 105px;
          backdrop-filter: blur(20px);
          border-radius: 20px;
          margin-bottom: 25px;
          backdrop-filter: blur(20px);
          transition: all 0.3s ease-in;
          background: linear-gradient(119.46deg,
              rgba(22, 22, 22, 0.2) -6.32%,
              rgba(22, 22, 22, 0) 80.34%);
          border-image-source: linear-gradient(118.76deg,
              #afafaf 0.27%,
              rgba(0, 0, 0, 0) 82.07%);

          @media screen and (max-width: 991px) {
            margin-bottom: 15px;
          }

          @media screen and (max-width: 767px) {
            width: 75px;
            height: 75px;

            img {
              width: 50%;
              aspect-ratio: 1/1;
            }
          }
        }

        &:nth-child(1) {
          .icon {
            &:hover {
              background: linear-gradient(119.46deg,
                  #ff88002d -6.32%,
                  rgba(255, 255, 255, 0) 80.34%);
            }
          }
        }

        &:nth-child(2) {
          .icon {
            &:hover {
              background: linear-gradient(119.46deg,
                  #3eb6b42d -6.32%,
                  rgba(255, 255, 255, 0) 80.34%);
            }
          }
        }

        &:nth-child(3) {
          .icon {
            &:hover {
              background: linear-gradient(119.46deg,
                  #d0bd352d -6.32%,
                  rgba(255, 255, 255, 0) 80.34%);
            }
          }
        }

        &:nth-child(4) {
          .icon {
            &:hover {
              background: linear-gradient(119.46deg,
                  #bbbbbb2d -6.32%,
                  rgba(255, 255, 255, 0) 80.34%);
            }
          }
        }

        &:nth-child(5) {
          .icon {
            &:hover {
              background: linear-gradient(119.46deg,
                  #42c0c92d -6.32%,
                  rgba(255, 255, 255, 0) 80.34%);
            }
          }
        }

        &:nth-child(6) {
          .icon {
            &:hover {
              background: linear-gradient(119.46deg,
                  #da32af2d -6.32%,
                  rgba(255, 255, 255, 0) 80.34%);
            }
          }
        }

        &:nth-child(7) {
          .icon {
            &:hover {
              background: linear-gradient(119.46deg,
                  #ff8a002d -6.32%,
                  rgba(255, 255, 255, 0) 80.34%);
            }
          }
        }

        &:nth-child(8) {
          .icon {
            &:hover {
              background: linear-gradient(119.46deg,
                  #65d2232d -6.32%,
                  rgba(255, 255, 255, 0) 80.34%);
            }
          }
        }

        p {
          text-align: center;
          font-size: 24px;
          font-weight: 500;
          color: $secondary;

          @media screen and (max-width: 1440px) {
            font-size: 22px;
          }

          @media screen and (max-width: 991px) {
            font-size: 18px;
          }
        }
      }
    }
  }

  .testimonials_section {
    .testimonial-video {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      position: relative;
      height: 100%;

      &.playing {
        .play-icon {
          display: none;
        }
      }

      &.paused {
        .pause-icon {
          display: none;
        }
      }

      .play-icon,
      .pause-icon {
        --size: 60px;
        height: var(--size);
        width: var(--size);
        position: absolute;
        z-index: 1;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        margin-left: 10px;
        margin-bottom: 10px;
        pointer-events: none;
      }

      .play-icon {
        cursor: pointer;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAACqUlEQVR42u2bPUtcQRSGj6Cr20W0yoJVsA/plRDEwgWF5A8ICdsobBWS/IQUi2i7dUgVW7FJYR2yqFitrIXaCH40KiT6pIhsssTk3jt3ZvaeyTw/4Jzz7s7He2fOiEQikUgkYg3GmGWZVTbYo8MxAMd02GODVZaZZayYhY9QpUGLNLRoUGWkOMVP0+SCrFzQZLrfpZeo0SYPbWqU+jVo6hxhgyPq3gcUc+xjk33m/BU/wTouWGfCR/nznOGKM+bdFj/ECq5ZYchV+aNs4YMtRl2UX2EXX+xSsV3+JAf45IBJu7++3/J/SqjYG/s79IMdK3OBkqepe/90zm8zPCyc/1xU85a/wG1fBdyykM80nNJvTnMYDEeeJ7NHMnecRcHEqVK2bJjzme1ydgF1ikQ9a/nDHBZKwCHD2QTUKBq1LOUP5PxUd0GbgfQCpozTfOcDi7zkI9+sS5hKL6BpmOKKZ90Yj/liWUAz/QJ6bpjibU+cQV5zaVHAecrFlKpxij82fR7x2aKEajoBDeME904zXlk7x2ikE9Ayjf/XiA/5ZEVAK03549zYFiAiwvO7w/Y83DCeLGDGPH5C5AfGq9svZpIFLLkSICLC05xb5FJyijWXAkQo8z7HJreWnGDTrQAREZ7w1TDFZnLwbfcCRBjkndH/sJ0cuuNDgIgILwxSdJLDnvgSYDRcT5KDXnsU8CZziuv/QID6IaR+EqtfRtVvZOqthHozp95Oa/+gUf9JGcJHvfpjFe0HW+qPFkM43NV+vK7+giOAKyb1l3wBXLOqv+gOoNUggGYP9e02ATQ8BdBypr7pL4C2ywAaXwNoPQ6g+TuA9vsAHkB0DYYbj+TnCUrXqep9BHQnQfczrK7N0PsQ7jcZep8i9gwovY9Be4RofY4biUQiEaX8AMUlGptWg+RKAAAAAElFTkSuQmCC');
      }

      .pause-icon {
        cursor: pointer;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAACVUlEQVR42u2bvU5CMRSAdRB0JDAYSRx9DJkIAyS68gQsmvAkxOjKO7gSVl6AAHHSYIK6mPizuejnoCEQjf259/b21H5rk+Z8N23vaXu6sRGJRCKRSGpQpsEp5wy4Ys4DAA/MuWLAOac0KPsZ+DYteozRYUyPFtv+BF+jzyumvNKnlnfoBTpck4RrOhTyGjRd7kmDe7rOBxRNbkiTG5rugt/nkiy4ZN9F+Ec8kxXPHGUb/BZnZM0ZW1mFX2KEC0aUsgi/ygxXzKimHf4Bt7jkloN0v77b8L8UqumN/Sl5ME1lLlBwNHV/n87J0wwHC+efi2rS8I/5yFXgg+NkScMTefOUIMHIKOcxzpHsM05fsMlU2Uk5YU6WbO+YC3Txia5p+EXuvBK4o2gm0ME3Oibhb1pt1Re02WWXNgurdtX2f1Nf4NAq/PLK8dbCuF3Nob5A36L79loPbeN2NX39BfTFovu9tT72jNvVvGguprRs5tiPXgzbtWjpCfS8FejpCYy9FRjrhF/h3VuBdypqgbrdf8aJANTVAideC5yoBS68FrhQCwy9FhiqBSZeC0zUAnOvBeZqgUevBR7VAm9eC7z9AwHxQ0j8JBa/jIr/kYlPJcQnc+LTaekbGvFbyhA29eKPVaQfbIk/WgzhcFf68br4C44ArpjEX/IFcM0q/qI7gFKDAIo9xJfbBFDwFEDJmfiivwDKLgMofA2g9DiA4u8Ayu8DeACxTDCyyZHcPEFZZqpyHwF9K8h+hrVMM+Q+hFvRkPsUcW1AyX0MuiYi9TluJBKJRITyCcWk/sofJN8qAAAAAElFTkSuQmCC');
      }
    }

    .testimonials_slider {
      // &.slick-slider {
      //   margin: 0 -15px;
      // }

      // .slick-slide {
      //   padding: 10px;
      //   margin-right: 15px;
      //   margin-left: 15px;
      // }

      .slick-list {
        margin: 0 -15px;

        .slick-slide {
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      .slick-track {
        display: flex;
      }

      .play {
        position: absolute;
        display: flex;
        align-content: center;
        justify-content: center;
        width: 90px;
        height: 90px;
        z-index: 9;
        cursor: pointer;
        background: transparent;
        border: none;
        box-shadow: none;
      }

      .pauseVbtn {
        position: absolute !important;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        opacity: 0;
      }

      .modal-video {
        position: unset;
        border-radius: 20px;
        border: 1px solid #333;
      }

      .slick-arrow {

        &.slick-prev,
        &.slick-next {
          position: absolute;
          top: -105px;
          right: 0;
          width: 52px;
          height: 52px;
          background-size: cover !important;

          &.slick-disabled {
            opacity: 0.4;
          }

          @media screen and (max-width: 991px) {
            top: -125px;
          }

          @media screen and (max-width: 600px) {
            top: -165px;
          }

          @media screen and (max-width: 475px) {
            width: 40px;
            height: 40px;
          }

          &::before {
            content: '';
          }
        }

        &.slick-prev {
          left: unset;
          right: 75px;
          background: url(../../public/assets/images/ecommerce-development-company/icons/testimonials-arrow-left.svg) no-repeat;

          @media screen and (max-width: 475px) {
            right: 60px;
          }
        }

        &.slick-next {
          background: url(../../public/assets/images/ecommerce-development-company/icons/testimonials-arrow-right.svg) no-repeat;
        }
      }

      .slide_item {
        height: 500px;
        position: relative;
        display: flex !important;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background: #222;

        @media screen and (max-width: 475px) {
          height: 400px;
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
          cursor: pointer;
        }

        .react-player__preview {
          border-radius: 20px;

          button {
            border: 0;
            box-shadow: none;
            background: transparent;
          }
        }

        .slide_bg {
          // position: absolute;
          width: auto;
          height: 100%;
          object-fit: cover;
        }

        .text_block_wrap {
          padding: 50px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;

          @media screen and (max-width: 1440px) {
            padding: 30px;
          }

          .quote_icon {
            svg {
              width: 50px;
              height: 40px;
            }
          }

          .text_quote {
            margin: 40px 0;

            @media screen and (max-width: 991px) {
              margin: 30px 0 35px;
            }

            p {
              color: #fff;
              font-size: 18px;

              @media screen and (max-width: 1440px) {
                font-size: 16px;
              }

              @media screen and (max-width: 991px) {
                font-size: 15px;
              }

              @media screen and (max-width: 767px) {
                line-height: 20px;
              }
            }
          }

          .clients_detail {
            display: flex;
            align-items: flex-start;

            img {
              margin-right: 20px;
              width: 75px;
            }

            h3 {
              font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
              font-weight: 700;
              color: #fff;
              line-height: 36px;
            }

            p {
              font-size: 16px;
              color: #fff;

              @media screen and (max-width: 475px) {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

  .faq_section {
    padding: 0;
    background: transparent;

    @media screen and (max-width: 475px) {
      .container {
        padding: 0;
      }
    }

    .accordion {
      position: relative;

      &.desktop {
        @media screen and (max-width: 991px) {
          display: none;
        }
      }

      &.mobile {
        @media screen and (min-width: 992px) {
          display: none;
        }
      }

      .accordion-item {
        background: #fff;
        border-bottom: 1px solid rgba(0, 0, 0, 0.01);
        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.05);
        width: 57%;
        padding: 24px 64px 24px 60px;
        position: relative;
        z-index: 9;

        @media screen and (max-width: 1200px) {
          width: 100%;
        }

        @media screen and (max-width: 475px) {
          padding: 24px 45px 24px 45px;
        }

        &.active {
          background: #f2fbff;
          border-radius: 0 !important;

          .accordion-button {
            &::before {
              background: $blue;
            }
          }
        }

        .accordion-button::after {
          position: absolute;
          right: -35px;
          width: 10px;
          height: 20px;
          filter: grayscale(1) brightness(8.5);
          background: url(../../public/assets/images/ecommerce-development-company/icons/faq-arrow.svg) no-repeat;

          @media screen and (max-width: 475px) {
            right: -35px;
          }
        }

        .accordion-button[aria-expanded='true']::after {
          transform: unset;
          filter: unset;
          background: url(../../public/assets/images/ecommerce-development-company/icons/faq-arrow.svg) no-repeat;
        }

        .accordion-button {
          font-size: 18px !important;
          font-weight: 600;

          @media screen and (max-width: 475px) {
            color: #000;
            line-height: 22px;
          }

          &::before {
            content: '';
            position: absolute;
            background: #f2fbff;
            border-radius: 100%;
            width: 24px;
            height: 24px;
            left: -42px;

            @media screen and (max-width: 475px) {
              width: 18px;
              height: 18px;
              left: -33px;
            }
          }
        }
      }

      .accordion-body {
        @media screen and (max-width: 475px) {
          font-size: 16px !important;
          color: #000;
          line-height: 22px;
        }
      }

      .accordian_body_wrap {
        position: absolute;
        z-index: 1;
        background: #f2fbff;
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        width: 50%;
        top: -32px;
        right: 0;
        height: 520px;

        @media screen and (min-width: 1201px) and (max-width: 1440px) {
          height: 520px;
        }

        @media screen and (max-width: 1200px) {
          width: 100%;
          border-radius: 0;
          height: auto;
        }

        @media screen and (max-width: 1200px) {
          position: unset;
        }

        .question {
          font-size: 22px;
          font-weight: 700;
          margin-bottom: 10px;
          color: $secondary;
        }

        .accordion-item {
          width: 100%;
          background: transparent;
          box-shadow: none;
          border: unset;
          position: absolute;
          top: 0;
          padding: 0;

          @media screen and (max-width: 1200px) {
            position: unset;
          }
        }

        .accordion-collapse {
          position: absolute;
          z-index: 9;
          left: 0;

          @media screen and (max-width: 1200px) {
            position: unset;
            margin-top: 40px;
          }
        }

        .accordion-body {
          position: relative;
          padding-top: 0;
          margin: 40px 32px 40px 120px !important;
          color: $secondary;
          height: 385px;
          @extend .custom_scrollbar;

          @media screen and (max-width: 1440px) {
            margin: 40px 32px 40px 80px !important;
          }

          @media screen and (max-width: 1200px) {
            padding: 20px !important;
            margin: 0 !important;
            height: auto;
          }
        }
      }
    }
  }

  footer {
    display: flex;
    margin-top: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    @media screen and (max-width: 991px) {
      padding: 0 !important;
    }

    .container {
      @media screen and (max-width: 991px) {
        padding: 0;

        >.row {
          flex-direction: column-reverse;
          --bs-gutter-x: 0;
          --bs-gutter-y: 0;
        }
      }
    }

    .left-side,
    .right-side {
      height: 100%;
      padding-top: calc(60px + (120 - 60) * ((100vw - 320px) / (1920 - 320))) !important;
      padding-bottom: 50px !important;

      @media (max-width: 991px) {}
    }

    .right-side {
      position: relative;
      display: flex;
      flex-direction: column;
      // width: 55%;
      padding: calc(60px + (120 - 60) * ((100vw - 320px) / (1920 - 320))) calc(15px + (135 - 15) * ((100vw - 320px) / (1920 - 300))) !important;
      background: #000 !important;
      box-shadow: 30vw 0 0 #000;

      @media (max-width: 991px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        padding: calc(60px + (120 - 60) * ((100vw - 320px) / (1920 - 320))) calc(15px + (165 - 15) * ((100vw - 320px) / (1920 - 300))) !important;
      }

      .bg_dark {
        background: #000;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
      }

      .section_titles {
        padding-bottom: 20px;

        @media (max-width: 991px) {
          padding-bottom: 0;
        }

        .sub_title {
          background: transparent;
          border: 1px solid rgba(255, 255, 255, 0.4);

          &::before {
            background: #fff;
          }
        }
      }

      .titles {
        display: flex;
        flex-direction: column;
        gap: 20px;
        color: #fff;

        h3 {
          font-size: 50px;
          font-weight: 700;

          @media (max-width: 1440px) {
            font-size: 40px;
          }

          @media (max-width: 991px) {
            font-size: 35px;
          }
        }

        p {
          font-size: 22px;
          color: #fff;
          line-height: 1.35;

          @media (max-width: 1440px) {
            font-size: 20px;
          }

          @media (max-width: 991px) {
            font-size: 18px;
          }
        }
      }

      .footer-form {
        margin-top: 50px;

        @media (max-width: 1440px) {
          margin-top: 30px;
        }

        @media (max-width: 1200px) {
          margin-top: 20px;
        }

        @media (max-width: 991px) {
          margin-top: 10px;
        }

        .submit-group {
          flex-direction: column;
          align-items: flex-end;
          gap: 40px;
          padding: 0;
          margin-top: 40px;

          @media (max-width: 1440px) {
            gap: 20px;
          }

          @media (max-width: 1200px) {
            gap: 16px;
          }

          @media (max-width: 991px) {
            gap: 14px;
          }
        }
      }

      .submitted {
        // background-image: none;
      }
    }

    .left-side {
      display: flex;
      flex-direction: column;
      // width: 45%;
      background: #fff;
      padding-right: 70px;
      padding-left: 0;

      @media (max-width: 991px) {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        padding-top: 60px;
        width: 100%;
        padding-left: calc(15px + (165 - 15) * ((100vw - 320px) / (1920 - 320))) !important;
      }

      .section_titles {
        padding-bottom: 0;

        .sub_title::before {
          background: #000;
        }
      }

      .titles {
        h3 {
          font-size: 50px;
          font-weight: 700;
          line-height: 60px;

          @media (max-width: 1440px) {
            font-size: 30px;
            line-height: 1.25;
          }

          @media (max-width: 991px) {
            font-size: 25px;
          }
        }
      }

      .links {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 60px;

        @media (max-width: 1440px) {
          margin-top: 40px;
          gap: 20px;
        }

        @media (max-width: 1200px) {
          margin-top: 30px;
          gap: 15px;
        }

        @media (max-width: 991px) {
          margin-top: 20px;
        }

        .link {
          display: flex;
          align-items: center;
          gap: 30px;
          color: inherit;
          text-decoration: none;
          outline: none;

          .icon {
            min-width: 24px;
            max-width: 24px;

            @media (max-width: 1440px) {
              min-width: 20px;
              max-width: 20px;
            }

            @media (max-width: 1200px) {
              min-width: 16px;
              max-width: 16px;
            }

            @media (max-width: 991px) {
              min-width: 14px;
              max-width: 14px;
            }

            img {
              @media (max-width: 767px) {
                width: 25px;
              }
            }
          }

          .label {
            font-size: 22px;
            font-weight: 600;
            line-height: 1.35;

            @media (max-width: 1440px) {
              font-size: 20px !important;
            }

            @media (max-width: 991px) {
              font-size: 18px !important;
            }
          }

          &:nth-child(1) {
            .label {
              font-size: 22px;

              @media (max-width: 991px) {
                font-size: 20px;
              }

              @media (max-width: 991px) {
                font-size: 18px;
              }
            }
          }

          &:hover,
          &:focus-visible {
            text-decoration: underline;
          }
        }
      }

      .logo {
        margin-top: 60px;
        height: 28px;

        img {
          height: 100%;
        }

        @media (max-width: 1440px) {
          margin-top: 50px;
          height: 26px;
        }

        @media (max-width: 1200px) {
          margin-top: 40px;
          height: 24px;
        }

        @media (max-width: 991px) {
          margin-top: 30px;
          height: 22px;
        }
      }

      .subtext {
        font-size: 18px;
        color: $secondary;
        margin-top: 25px;
        line-height: 1.5;

        @media (max-width: 1200px) {
          margin-top: 16px;
        }

        @media (max-width: 767px) {
          font-size: 16px;
        }
      }

      .social-links {
        margin-top: 40px;
        display: flex;
        align-items: center;

        @media (max-width: 1440px) {
          margin-top: 30px;
        }

        @media (max-width: 1200px) {
          margin-top: 20px;
        }

        @media (max-width: 991px) {
          margin-top: 16px;
        }

        .social-link {
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 15px;

          img {
            height: 30px;
            width: 30px;
          }

          &:hover {
            img {
              transform: scale(1.1);
            }
          }

          &:focus-visible {}
        }
      }
    }

    .pattern {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      overflow: hidden;
      z-index: 1;

      @media (max-width: 991px) {
        display: none;
      }

      img {
        position: absolute;
        right: -40px;
        bottom: -40px;
      }
    }
  }
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
  z-index: 1;
  position: relative;

  @media (max-width: 1440px) {
    gap: 40px;
  }

  // @media (max-width: 1200px) {
  //   gap: 15px;
  // }

  .phone_wrap {
    margin-top: 3px;

    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
  }

  .input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;

    &.hnpt {
      display: none;
    }

    label {
      position: absolute;
      top: -5px;
      color: #fff;
      opacity: 0.5;
      font-size: 18px;
      transform: translateY(70%);
      transition: all 0.15s ease-out;
      pointer-events: none;
      user-select: none;
      will-change: font-size, transform;

      @media (max-width: 1200px) {
        font-size: 16px;
      }

      @media (max-width: 991px) {
        font-size: 14px;
      }
    }

    textarea {
      height: 100px;

      @media (max-width: 1440px) {
        height: 80px;
      }
    }

    input,
    textarea,
    .PhoneInput {
      border-radius: 0;
      width: 100%;
      padding: 5px 0 15px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0);
      font-size: 18px;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      font-family: 'Inter', sans-serif !important;
      transition: border-color 1s cubic-bezier(0, -0.01, 0, 1.31);

      .PhoneInputInput {
        border: 0;
      }

      &:focus-visible {
        outline: none;
        border-color: rgba(255, 255, 255, 1);
      }

      &::placeholder {
        color: #aaa !important;
        user-select: none !important;
      }

      &:focus-within+label {
        font-size: 16px;
        transform: translateY(-100%);
        opacity: 1;

        @media (max-width: 1200px) {
          font-size: 14px;
        }
      }

      &:not(:placeholder-shown)+label {
        font-size: 16px;
        transform: translateY(-100%);
        opacity: 1;

        @media (max-width: 1200px) {
          font-size: 14px;
        }
      }

      &#description {
        width: 100%;
        height: 100px;
        resize: none;
        line-height: 1.5;

        @media (max-width: 1440px) {
          height: 90px;
        }

        @media (max-width: 1200px) {
          height: 80px;
        }
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        transition: background-color 9999s ease-in-out 0s,
          color 9999s ease-in-out 0s;
      }

      @media (max-width: 1440px) {
        font-size: 16px;
      }
    }

    .form-submit-btn {
      border-radius: 500px;
      font-size: 18px;
      color: #000;
      background-color: var(--red);
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100px;
      min-width: 100px;
      cursor: pointer;
      will-change: transform;
      transition: all 1s cubic-bezier(0, -0.01, 0, 1.31);
      position: relative;
      user-select: none;

      &:focus-visible {
        outline: 1px solid #000;
        transform: scale(1.1);
        outline-offset: 4px;
      }

      &:active {
        transform: scale(0.9);
      }

      @media (max-width: 1440px) {
        font-size: 16px;
        min-height: 80px;
        min-width: 80px;
      }

      @media (max-width: 991px) {
        min-height: 70px;
        min-width: 70px;
      }
    }
  }

  &.submitting {
    .form-submit-btn {
      color: rgba(255, 255, 255, 0);
      cursor: wait;
    }

    .form-submit-btn::after {
      // height: 30px;
      // width: 30px;
      // content: "";
      // position: absolute;
      // border: solid white;
      // border-radius: 500px;
      // border-right-color: rgba(255, 255, 255, 0);
      // animation: loading-loop-anim 0.4s linear infinite;

      filter: invert(1);
      content: '';
      position: absolute;
      height: 24px;
      width: 24px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNNiAxMGMtMS4xIDAtMiAuOS0yIDJzLjkgMiAyIDIgMi0uOSAyLTItLjktMi0yLTJ6bTEyIDBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yem0tNiAwYy0xLjEgMC0yIC45LTIgMnMuOSAyIDIgMiAyLS45IDItMi0uOS0yLTItMnoiLz48L3N2Zz4=');
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0;
      animation: button-loading-anim 0.3s ease-out alternate infinite;

      @keyframes button-loading-anim {
        to {
          opacity: 1;
          transform: scale(1.1);
        }
      }

      @media (max-width: 1440px) {
        height: 25px;
        width: 25px;
      }

      @media (max-width: 1200px) {
        height: 20px;
        width: 20px;
      }

      @media (max-width: 991px) {
        height: 16px;
        width: 16px;
      }
    }

    @keyframes loading-loop-anim {
      to {
        transform: rotate(360deg);
      }
    }
  }

  .submit-group {
    padding: 20px 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    user-select: none;

    @media (max-width: 1440px) {
      gap: 30px;
    }

    @media (max-width: 1200px) {
      gap: 20px;
    }

    @media (max-width: 991px) {
      gap: 12px;
    }

    p {
      color: #000;
      opacity: 0.5;
      font-size: 16px;
      line-height: 24px;

      @media (max-width: 1200px) {
        font-size: 14px;
      }
    }
  }
}

// wp-hirefullstackdeveloperindia-new
.contactus_modal {
  display: flex;
  margin-top: 0;
  z-index: 99999;

  .contact-form {
    gap: 30px;

    @media (max-width: 1440px) {
      gap: 20px;
    }
  }

  .modal-dialog {
    max-width: 1320px;

    .modal-content {
      border-radius: 20px;
      background: $secondary;
    }

    .modal-header {
      padding: 0;
      border: 0;

      .btn-close {
        position: absolute;
        right: 20px;
        top: 20px;
        background-color: $blue;
        z-index: 9;
        opacity: 1;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        filter: grayscale(1) brightness(5);

        @media screen and (max-width: 991px) {
          right: 30px;
          top: 30px;
          filter: grayscale(1) brightness(507);
        }
      }
    }

    .modal-body {
      padding: 0;
    }
  }

  .contact_modal_content {
    display: flex;

    @media screen and (max-width: 991px) {
      flex-direction: column-reverse;
    }
  }

  .right-side {
    padding: 60px 100px 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0 20px 20px 0;

    .logo {
      margin-top: 0 !important;

      @media (min-width: 992px) {
        display: none;
      }
    }

    @media screen and (max-width: 1440px) {
      padding: 50px 70px 0;
    }

    @media screen and (max-width: 1200px) {
      padding: 50px 50px 10px;
    }

    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .contact-form {

      input,
      textarea {
        &::placeholder {
          color: #aaa !important;
        }
      }
    }

    .section_titles {
      padding-bottom: 20px;

      .sub_title {
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.4);

        &::before {
          background: #fff;
        }
      }
    }

    .titles {
      display: flex;
      flex-direction: column;
      color: #fff;

      h3 {
        font-size: 40px;
        font-weight: 700;

        @media (max-width: 1440px) {
          font-size: 30px;
        }

        @media (max-width: 991px) {
          font-size: 25px;
        }
      }

      p {
        font-size: 20px;
        color: #fff;
        margin-bottom: 0;

        @media (max-width: 1440px) {
          font-size: 18px;
        }
      }
    }

    .footer-form {
      margin-top: 50px;

      @media (max-width: 1440px) {
        margin-top: 30px;
      }

      @media (max-width: 1200px) {
        margin-top: 20px;
      }

      @media (max-width: 991px) {
        margin-top: 10px;
      }

      .submit-group {
        .btn_primary {
          border: none;
          background: $blue;
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          border-radius: 10px;
          padding: 14px 34px;
          text-decoration: none;
          display: inline-block;

          @media (max-width: 1440px) {
            gap: 20px;
          }

          @media (max-width: 1200px) {
            gap: 16px;
          }

          @media (max-width: 991px) {
            gap: 14px;
          }
        }
      }
    }

    .submitted {
      // background-image: none;
    }
  }

  .logo {
    margin-bottom: 60px;
    height: 28px;

    @media (max-width: 1440px) {
      margin-bottom: 30px;
    }

    img {
      height: 100%;
      filter: grayscale(1) brightness(10);
    }

    @media (max-width: 1440px) {
      height: 26px;
    }

    @media (max-width: 1440px) {
      margin-top: 10px;
      height: 24px;
    }

    @media (max-width: 991px) {
      margin-top: 30px;
      height: 22px;
    }
  }

  .left-side {
    padding: 75px 50px 20px;
    display: flex;
    flex-direction: column;
    max-width: 530px;
    width: 100%;
    background: $blue;
    padding-right: 70px;
    margin: 10px;
    border-radius: 20px;

    .logo {
      @media (max-width: 991px) {
        display: none;
      }
    }

    @media screen and (max-width: 1440px) {
      padding: 60px 30px 20px;
    }

    @media screen and (max-width: 1200px) {
      padding: 30px 20px;
    }

    @media (max-width: 991px) {
      max-width: calc(100% - 20px);
      display: flex;
      flex-direction: column;
      overflow: hidden;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .section_titles {
      padding-bottom: 20px;

      .sub_title::before {
        background: #000;
      }
    }

    .titles {
      h3 {
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
        color: #fff;

        @media (max-width: 1440px) {
          font-size: 30px;
        }

        @media (max-width: 991px) {
          font-size: 25px;
        }
      }
    }

    .links {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-top: 60px;

      @media (max-width: 1440px) {
        margin-top: 40px;
      }

      @media (max-width: 1200px) {
        margin-top: 30px;
      }

      @media (max-width: 991px) {
        margin-top: 20px;
      }

      .link {
        display: flex;
        align-items: center;
        gap: 30px;
        color: inherit;
        text-decoration: none;
        outline: none;

        .icon {
          min-width: 24px;
          max-width: 24px;

          img {
            filter: grayscale(1) brightness(10);

            @media (max-width: 767px) {
              width: 25px;
            }
          }

          @media (max-width: 1440px) {
            min-width: 20px;
            max-width: 20px;
          }

          @media (max-width: 1200px) {
            min-width: 16px;
            max-width: 16px;
          }

          @media (max-width: 991px) {
            min-width: 14px;
            max-width: 14px;
          }
        }

        .label {
          font-size: 20px;
          font-weight: 400;
          line-height: 1.35;
          color: #fff;

          @media (max-width: 1440px) {
            font-size: 20px;
          }

          @media (max-width: 991px) {
            font-size: 18px;
          }

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }

        &:nth-child(1) {
          .label {
            font-size: 22px;

            @media (max-width: 1440px) {
              font-size: 20px;
            }

            @media (max-width: 991px) {
              font-size: 18px;
            }
          }
        }

        &:hover,
        &:focus-visible {
          // text-decoration: underline;
        }
      }
    }

    .subtext {
      font-size: 18px;
      color: $secondary;
      margin-top: 25px;
      line-height: 1.5;

      @media (max-width: 1200px) {
        margin-top: 16px;
      }
    }

    .social-links {
      margin-top: 50px;
      display: flex;
      align-items: center;

      .social-link {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;

        img {
          height: 30px;
          width: 30px;
          filter: grayscale(1) brightness(50);
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        &:focus-visible {}
      }
    }
  }

  .pattern {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;

    @media (max-width: 991px) {
      display: none;
    }

    img {
      position: absolute;
      right: -40px;
      bottom: -40px;
    }
  }
}

// CUSTOM SCROLL

.custom_scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 20px !important;

  @media screen and (max-width: 991px) {
    padding-right: 0;
  }
}

.custom_scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f9f9f9;
}

.custom_scrollbar::-webkit-scrollbar {
  width: 2px;
  background-color: #f9f9f9;
}

.custom_scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #ddd;
}

.modal-backdrop.show {
  z-index: 9 !important;
}

.alert-error {
  color: #e15632 !important;
  font-size: 13px !important;
  margin-top: 3px;
}

[data-aos='white_line'] {
  width: 0%;

  &.aos-animate {
    width: 100%;
  }
}
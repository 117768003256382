// _variable.scss
//---------------------------------------------------------//
// bootstrap custom color set
//----------------------------------------------------------//
@import 'bootstrap/scss/bootstrap';

$primary: #0010f1;
$secondary: #161616;
$common-text: #555;
$white: #fff;

$theme-colors: (
  'primary': #0010f1,
  'secondary': #161616,
  'common': #969696,
);

$text: #969696;
//---------------------------------------------------------//
// breakpoint
//----------------------------------------------------------//
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);

@function breakpoint-next(
  $name,
  $breakpoints: $grid-breakpoints,
  $breakpoint-names: map-keys($breakpoints)
) {
  $n: index($breakpoint-names, $name);
  @return if(
    $n < length($breakpoint-names),
    nth($breakpoint-names, $n + 1),
    null
  );
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min !=0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 0.02px, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) ==null, '', '-#{$name}');
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media screen and (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between(
  $lower,
  $upper,
  $breakpoints: $grid-breakpoints
) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min !=null and $max !=null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max==null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min==null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min !=null and $max !=null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max==null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min==null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}

//---------------------------------------------------------//
// container-width
//----------------------------------------------------------//
// Grid containers
$container-max-widths: (
  xs: 100%,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
  xxl: 1600px,
);

@mixin make-max-widths-container-width(
  $max-widths: $container-max-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

.container {
  @include make-max-widths-container-width();
}

//---------------------------------------------------------//
// font-size
//----------------------------------------------------------//
$font-size: (
  8px: 8px,
  9px: 9px,
  10px: 10px,
  11px: 11px,
  12px: 12px,
  13px: 13px,
  14px: 14px,
  15px: 15px,
  16px: 16px,
  17px: 17px,
  18px: 18px,
  19px: 19px,
  20px: 20px,
  21px: 21px,
  22px: 22px,
  23px: 23px,
  24px: 24px,
  25px: 25px,
  26px: 26px,
  27px: 27px,
  28px: 28px,
  29px: 29px,
  30px: 30px,
  31px: 31px,
  32px: 32px,
  33px: 33px,
  34px: 34px,
  35px: 35px,
  36px: 36px,
  37px: 37px,
  38px: 38px,
  39px: 39px,
  40px: 40px,
  41px: 41px,
  42px: 42px,
  45px: 45px,
  46px: 46px,
  48px: 48px,
  55px: 55px,
  56px: 56px,
  64px: 64px,
  70px: 70px,
  72px: 72px,
  75px: 75px,
);

// Responsive widths
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $name, $value in $font-size {
      // font-size:
      .fs#{$infix}-#{$name} {
        font-size: $value !important;
      }
    }
  }
}

//---------------------------------------------------------//
// font-weight
//----------------------------------------------------------//
$font-weight: (
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
  900: 900,
);

// Responsive widths
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $name, $value in $font-weight {
      // font-size:
      .fw#{$infix}-#{$name} {
        font-weight: $value !important;
      }
    }
  }
}

//---------------------------------------------------------//
// @mixin
//----------------------------------------------------------//
@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -ms-transform: $property;
  -o-transform: $property;
  transform: $property;
}

@mixin border-radius($property) {
  -webkit-border-radius: $property;
  -moz-border-radius: $property;
  -ms-border-radius: $property;
  -o-border-radius: $property;
  border-radius: $property;
}

@mixin box-shadow($property) {
  -webkit-box-shadow: $property;
  -moz-box-shadow: $property;
  -ms-box-shadow: $property;
  -o-box-shadow: $property;
  box-shadow: $property;
}

@mixin transition($property) {
  -webkit-transition: $property;
  -moz-transition: $property;
  -ms-transition: $property;
  -o-transition: $property;
  transition: $property;
}

@mixin animation($property) {
  -webkit-animation: $property;
  -moz-animation: $property;
  -ms-animation: $property;
  -o-animation: $property;
  animation: $property;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    $browser: '-webkit-' !global;
    @content;
  }

  @-moz-keyframes #{$animationName} {
    $browser: '-moz-' !global;
    @content;
  }

  @-o-keyframes #{$animationName} {
    $browser: '-o-' !global;
    @content;
  }

  @keyframes #{$animationName} {
    $browser: '' !global;
    @content;
  }
}

$browser: null;

// @extend .transition;
.transition {
  @include transition(all 0.1s ease-in-out);
}

.transition-lg {
  @include transition(all 0.3s ease-in-out);
}

// @extend .shadow;
.shadow {
  @include box-shadow(0 2px 6px rgba($dark, 0.08) !important);
}

.rounded-sm {
  @include border-radius(3px !important);
}

.rounded-md {
  @include border-radius(8px !important);
}

.rounded-lg {
  @include border-radius(10px !important);
}

.rounded-xl {
  @include border-radius(15px !important);
}

.rounded-circle {
  @include border-radius(50% !important);
}

.rounded-0 {
  @include border-radius(0 !important);
}

.btn-primary {
  @extend .fs-xl-18px;
  @extend .fs-md-17px;
  @extend .fs-16px;
  @extend .fw-500;
  @extend .position-relative;
  @extend .rounded-0;
  @extend .border-0;
  @extend .bg-transparent;
  line-height: 18px;
  padding: 0 28px 0 0;

  @media screen and (max-width: 1199px) {
    padding: 0;
  }

  &:before {
    content: '';
    @extend .d-inline-block;
    @include transition(all 0.35s ease);
    @extend .position-absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: $primary;
    width: 5%;
  }

  span {
    &:first-child {
      @extend .d-inline-block;
      @extend .position-relative;
      @extend .d-inline-block;
      @extend .text-white;
      background-color: $primary;
      z-index: 111;
      padding: 17px 5px 17px 28px;
      line-height: 1;
    }

    &:last-child {
      @extend .d-inline-block;
      @extend .position-relative;
      @extend .text-white;
      @include transition(all 0.4s ease);
      z-index: 111;
    }
  }

  &:hover {
    @extend .bg-transparent;

    &:before {
      @extend .w-100;
    }
  }

  &:focus {
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }

  &.btn-black {
    span {
      &:last-child {
        color: $secondary !important;
      }
    }

    &:hover {
      span {
        &:last-child {
          @extend .text-white;
        }
      }
    }
  }

  &.btn-white {
    &:before {
      @extend .bg-white;
    }

    span {
      @extend .bg-white;
      color: $secondary !important;

      &:last-child {
        color: $white !important;
        background: transparent !important;
      }
    }

    &:hover {
      span {
        &:last-child {
          color: $secondary !important;
        }
      }
    }
  }
}

.opacity5 {
  opacity: 0.5;
}

.opacity6 {
  opacity: 0.6;
}
.opacity55 {
  opacity: 0.55;
}
.opacity7 {
  opacity: 0.7;
}

.darks_bg {
  background-color: #161616;
}

.secondry-bg-color {
  background-color: #161616;
}

.dark_blue_bg {
  background-color: #0010f1;
}

.dark_blue_clr {
  color: #0010f1;
}

.text-decor {
  text-decoration: none;
}

.bg-F8F8F8 {
  background-color: #f8f8f8;
}
.secondry-color {
  color: #161616 !important;
}
.text-light,
.whiteBox:hover .over-text,
.text-light-sec,
.text-light-third {
  color: rgba(255, 255, 255, 0.55) !important;
}

@font-face {
  font-family: 'Jost';
  src: url('../../public/assets/fonts/Jost-Book.eot');
  src: url('../../public/assets/fonts/Jost-Book.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/assets/fonts/Jost-Book.woff2') format('woff2'),
    url('../../public/assets/fonts/Jost-Book.woff') format('woff'),
    url('../../public/assets/fonts/Jost-Book.ttf') format('truetype'),
    url('../../public/assets/fonts/Jost-Book.svg#Jost-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bodoni';
  src: url('../../public/assets/fonts/BodoniModa06pt-Book.eot');
  src: url('../../public/assets/fonts/BodoniModa06pt-Book.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/assets/fonts/BodoniModa06pt-Book.woff2') format('woff2'),
    url('../../public/assets/fonts/BodoniModa06pt-Book.woff') format('woff'),
    url('../../public/assets/fonts/BodoniModa06pt-Book.ttf') format('truetype'),
    url('../../public/assets/fonts/BodoniModa06pt-Book.svg#BodoniModa06pt-Book')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --purple-0: #fdf5fe;
  --purple-1: #e4d0ff;
  --purple-2: #ca9dff;
  --purple-3: #b576ff;
  --purple-4: #ab4bff;
  --purple-5: #7510d8;
  --purple-6: #4b009e;
  --purple-7: #2e006b;
  --purple-8: #150046;
  --purple-9: #020012;
  --title-color: #fff5ff;

  --smooth-ease-1: cubic-bezier(0, -0.01, 0, 1.31);
  --smooth-ease-2: cubic-bezier(0.11, 0.86, 0.45, 1.01);
}

.gen_ai_landing_page {
  h1,
  h2,
  h3,
  p,
  ul,
  li {
    line-height: 1;
    margin: 0;
    padding: 0;
  }

  .bodoni {
    font-family: 'Bodoni', serif;
  }

  background-color: var(--purple-9);
  color: var(--purple-0);
  letter-spacing: -0.2px;
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  overflow: hidden;

  img {
    width: 100%;
    user-select: none;
    pointer-events: none;
  }

  .overline {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;

    @media (min-width: 1024px) {
      font-size: 20px;
    }
  }

  .heading-1 {
    font-size: 28px;
    letter-spacing: -0.2px;
    line-height: 120%;

    @media (min-width: 1024px) {
      font-size: 32px;
    }

    @media (min-width: 1366px) {
      font-size: 40px;
    }
  }

  /**********************************/

  .intro {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .intro-art-wrapper {
      width: 100%;

      img,
      picture {
        width: 100%;
        height: 100vh;
        border-radius: 20px;
        object-fit: cover;
      }
    }

    .intro-titles {
      position: absolute;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0 10px;

      h1 {
        color: var(--purple-3);
      }

      h2 {
        max-width: 750px;
        color: var(--title-color);
      }
    }
  }

  .about-block {
    padding: 40px 0;

    @media (min-width: 768px) {
      padding: 60px 0;
    }

    @media (min-width: 1366px) {
      padding: 120px 0;
    }

    .sub-data {
      font-size: 20px;
      color: #fff;
      line-height: 120%;
      margin-bottom: 30px;

      @media (min-width: 1366px) {
        font-size: 30px;
        margin-bottom: 60px;
      }
    }

    .left-section {
      max-width: 550px;
    }

    .number-data {
      &:not(:last-child) {
        border-bottom: 1px solid var(--purple-6);
        padding-bottom: 25px;
        margin-bottom: 10px;

        @media (min-width: 1024px) {
          padding-bottom: 38px;
          margin-bottom: 20px;
        }
      }

      .number,
      .stat-number {
        font-size: 40px;
        background: linear-gradient(
          90deg,
          #fff 0%,
          #fff 20%,
          var(--purple-3) 60%,
          var(--purple-1) 65%,
          var(--purple-5) 70%,
          var(--purple-9) 80%,
          var(--purple-9) 100%
        );
        background-position: 160% 0%;
        background-size: 200% 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (min-width: 1366px) {
          font-size: 80px;
        }
      }

      .stat-number {
        display: flex;
        .stat-number-unit {
          max-height: 40px;
          line-height: 1;
          overflow: hidden;

          @media (min-width: 1366px) {
            max-height: 80px;
          }

          .stat-number-items {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .sub-title {
        font-size: 16px;
        color: var(--purple-2);

        @media (min-width: 1366px) {
          font-size: 24px;
        }
      }
    }

    .right-section {
      margin-top: 30px;

      @media (min-width: 768px) {
        margin-top: 0;
      }

      img {
        border-radius: 20px;

        & + img {
          margin-top: 20px;
        }
      }
    }
  }

  .chat-section {
    background-color: var(--purple-5);
    padding: 40px 0;

    &.first-chat-section {
      background: linear-gradient(
        to top,
        var(--purple-5) 30%,
        var(--purple-9) 100%
      );
    }

    @media (min-width: 768px) {
      padding: 60px 0;
    }

    @media (min-width: 1366px) {
      padding: 116px 0;
    }

    .left-section {
      .chat-title {
        font-size: 26px;
        line-height: 120%;
        margin-bottom: 30px;

        @media (min-width: 768px) {
          margin-bottom: 50px;
        }

        @media (min-width: 1024px) {
          font-size: 32px;
          margin-bottom: 105px;
        }

        @media (min-width: 1366px) {
          font-size: 40px;
        }
      }

      ol {
        font-size: 16px;
        margin-bottom: 20px;

        @media (min-width: 768px) {
          font-size: 18px;
        }

        @media (min-width: 1024px) {
          font-size: 24px;
          margin-bottom: 30px;
        }

        li {
          padding-bottom: 15px;
        }
      }

      .hour-sec {
        border-top: 1px solid var(--purple-6);
        padding-top: 20px;
        margin-bottom: 50px;

        @media (min-width: 1024px) {
          margin-bottom: 100px;
        }

        .hour {
          font-size: 22px;
          line-height: 120%;

          @media (min-width: 1024px) {
            font-size: 30px;
          }
        }

        .save {
          font-size: 16px;
          color: var(--purple-2);

          @media (min-width: 1024px) {
            font-size: 24px;
          }
        }
      }
    }

    .chat-body-wrapper {
      perspective: 2000px;
    }

    .chat-body {
      border: 1px solid var(--purple-5);
      box-shadow: inset 0 -50px 100px 0 var(--purple-6),
        0 50px 50px 0 var(--purple-6);
      background-image: url(../../public/assets/images/generative-ai/chat-bg.jpg);
      background-size: cover;
      border-radius: 20px;
      min-height: 500px;
      display: flex;
      flex-direction: column;

      @media (min-width: 1200px) {
        min-height: 792px;
        box-shadow: inset 0 -50px 200px 0 var(--purple-6),
          0 100px 100px 0 var(--purple-6);
      }

      .chat-heading {
        font-size: 22px;
        line-height: 120%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding: 20px;

        @media (min-width: 1024px) {
          font-size: 30px;
          padding: 30px;
        }

        span {
          padding-left: 20px;
          width: calc(100% - 32px);
        }
      }

      .chat-content {
        padding: 20px;

        @media (min-width: 1024px) {
          padding: 30px;
        }

        @media (min-width: 1200px) {
          max-height: 570px;
        }

        .message {
          margin-bottom: 20px;
          max-width: 90%;

          @media (min-width: 1024px) {
            max-width: 75%;
          }

          .msg-wrapper {
            color: #000;
            background-color: #fff;
            padding: 8px 20px;
            font-size: 16px;
            line-height: 125%;
            border-radius: 2px 20px 20px 10px;
            display: inline-block;

            p,
            ol,
            ul {
              font-size: 16px;
              line-height: 125%;
              margin: 8px 0;

              li {
                font-size: 16px;
                line-height: 125%;
              }
            }

            ol,
            ul {
              padding-left: 20px;
            }

            .icon {
              background-color: var(--purple-1);
              border-radius: 50px;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .file-name {
              width: calc(100% - 40px);
              padding-left: 10px;
              word-break: break-all;
            }
          }

          &.sender {
            display: flex;
            justify-content: flex-end;
            margin-left: auto;

            .msg-wrapper {
              background-color: var(--purple-1);
              border-radius: 20px 2px 10px 20px;
            }
          }

          &.card-data {
            max-width: 100%;
          }

          .scroll-section {
            -ms-overflow-style: none;
            scrollbar-width: none;
            mask-image: linear-gradient(
              90deg,
              #000 12.5%,
              #000 87.5%,
              transparent
            );
            -webkit-mask-image: linear-gradient(
              90deg,
              #000 12.5%,
              #000 87.5%,
              transparent
            );
            overflow-x: scroll;

            &::-webkit-scrollbar {
              display: none;
            }
          }

          .card-wrapper {
            .card {
              width: 190px;
              min-width: 190px;
              border-radius: 20px;
              padding: 5px;
              text-decoration: none;

              img {
                border-radius: 15px;
                height: 140px;
                object-fit: cover;
              }

              .pname {
                color: #020012;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .price {
                font-size: 14px;
                color: #020012;
                font-weight: bold;
              }
            }
          }
        }
      }

      .input-box {
        padding: 20px 20px 30px 20px;
        margin-top: auto;

        @media (min-width: 1024px) {
          padding: 30px 30px 40px 30px;
        }

        input {
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 245, 255, 0.2);
          backdrop-filter: blur(50px);
          border-radius: 50px;
          color: rgba(255, 255, 255, 0.5);
          font-size: 18px;
          line-height: 150%;
          padding: 10px 50px 10px 20px;

          @media (min-width: 1024px) {
            font-size: 20px;
          }

          &::placeholder {
            color: rgba(255, 255, 255, 0.5);
          }
        }

        .submit-btn {
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 5;
          background-color: transparent;
          padding: 0 20px !important;

          &:before {
            display: none !important;
          }
        }

        .option-data {
          width: 100%;
          margin-bottom: 15px;

          @media (min-width: 1024px) {
            width: 35%;
            margin-bottom: 0px;
          }

          @media (min-width: 1200px) {
            width: 30%;
          }

          .option-btn {
            background: rgba(255, 255, 255, 0.1);
            border: 1px solid #fff5ff !important;
            backdrop-filter: blur(50px);
            border-radius: 50px !important;
            font-size: 20px !important;
            padding: 13px 20px !important;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;

            @media (min-width: 1024px) {
              padding: 15px 20px !important;
            }

            span {
              background-color: transparent;
              padding: 0 !important;
            }

            &:before {
              display: none !important;
            }
          }
        }

        .option-form {
          width: 100%;

          @media (min-width: 1024px) {
            width: 65%;
            padding-left: 15px;
          }

          @media (min-width: 1200px) {
            width: 70%;
          }
        }
      }
    }
  }

  .did-you-know-collection {
    position: relative;
    min-height: 100vh;

    section {
      position: absolute;
      width: 100%;
      height: 100%;

      &.did-you-know-1 {
        z-index: 1;
      }
      &.did-you-know-2 {
        z-index: 2;
        opacity: 0;
      }
      &.did-you-know-3 {
        z-index: 3;
        opacity: 0;
      }
    }
  }

  /*****************/

  .did-you-know-group {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: var(--purple-1);
    padding: 100px 0;
    background-color: var(--purple-5);
    will-change: background-color;

    @media (min-width: 1200px) {
      padding: 200px 0;
    }

    .did-you-know-art {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      mix-blend-mode: overlay;
      opacity: 0.2;
      will-change: opacity;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        user-select: none;
        pointer-events: none;
        object-fit: cover;
      }
    }

    .did-you-know-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      top: 0;
      z-index: 1;
      position: relative;

      .container {
        position: absolute;

        &.container-2,
        &.container-3 {
          opacity: 0;
          transform: translateY(10px);
        }
      }

      .section-heading {
        font-size: 30px;
        letter-spacing: -0.2px;
        line-height: 120%;
        will-change: opacity, transform;

        @media (min-width: 768px) {
          font-size: 32px;
        }

        @media (min-width: 1024px) {
          font-size: 40px;
        }

        @media (min-width: 1366px) {
          font-size: 48px;
        }

        .highlight {
          color: var(--purple-0);
        }

        &.section-heading-2 {
          color: var(--purple-2);
        }

        &.section-heading-3 {
          color: var(--purple-0);

          .highlight {
            color: var(--purple-3);
          }
        }
      }

      h3 {
        color: var(--purple-0);
        margin-bottom: 20px;
        font-size: 16px;

        @media (min-width: 1024px) {
          font-size: 20px;
        }
      }

      h3.caption-text {
        color: var(--purple-2);
        margin-bottom: 20px;
        font-size: 16px;

        @media (min-width: 1024px) {
          font-size: 20px;
        }
      }
    }
  }

  .comparison-group {
    position: relative;
  }

  .before-section-group {
    position: absolute;
    padding: 100px 0;
    min-height: 100vh;
    width: 100%;

    @media (min-width: 1366px) {
      padding: 200px 0;
    }

    .before-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 25px;
      margin-bottom: 40px;
      position: relative;

      @media (min-width: 768px) {
        margin-bottom: 60px;
      }

      @media (min-width: 1200px) {
        margin-bottom: 90px;
      }

      .before-title-section {
        position: absolute;
        left: var(--bs-gutter-x, 0.75rem);

        @media (max-width: 575px) {
          left: 30px;
        }
      }

      .before-icon {
        width: 70px;
        height: 70px;
        margin-right: calc(-100% + 70px);
        mix-blend-mode: luminosity;

        img {
          mix-blend-mode: luminosity;
        }
      }

      .after-icon {
        position: absolute;
        z-index: 1;
        width: 70px;
        height: 70px;
        mix-blend-mode: luminosity;
        transform: translate(calc(50vw - 150%), 0);

        img {
          filter: blur(0.25px);
          animation: after-icon-anim 0.075s infinite;

          @keyframes after-icon-anim {
            25% {
              transform: translateX(-1px);
            }
            50% {
              transform: translateX(1px);
            }
            75% {
              transform: translateX(0);
            }
          }
        }

        &::after {
          content: '';
          height: 40px;
          width: 40px;
          border: 20px solid;
          border-color: var(--purple-0);
          mix-blend-mode: overlay;
          border-top-color: transparent;
          border-left-color: transparent;
          border-right-color: transparent;
          filter: blur(10px);
          position: absolute;
          top: 40%;
          left: 0%;
          transform: rotate(40deg) scaleY(5);
          animation: after-icon-smoke-anim 0.05s infinite;

          @keyframes after-icon-smoke-anim {
            25% {
              opacity: 0.1;
            }
            50% {
              opacity: 0.4;
            }
            100% {
              opacity: 0.6;
            }
          }
        }
      }
    }

    .caption-text {
      color: var(--purple-2);
      margin-bottom: 10px;
      text-transform: uppercase;
      font-size: 16px;

      @media (min-width: 1024px) {
        font-size: 20px;
      }
    }

    .section-heading {
      font-size: 28px;
      letter-spacing: -0.2px;
      line-height: 120%;
      color: var(--purple-1);
      margin-bottom: 0px;

      @media (min-width: 768px) {
        font-size: 30px;
      }

      @media (min-width: 1024px) {
        font-size: 36px;
      }

      @media (min-width: 1366px) {
        font-size: 40px;
      }
    }

    ul {
      list-style-type: none;
      width: 100%;

      li {
        border-top: 1px solid var(--purple-5);
        padding-top: 10px;
        color: var(--purple-0);
        width: 100%;
        line-height: 1.35;
        font-size: 18px;

        @media (min-width: 768px) {
          font-size: 20px;
        }

        @media (min-width: 1024px) {
          font-size: 24px;
        }

        &:not(:first-child) {
          margin-top: 25px;

          @media (min-width: 1024px) {
            margin-top: 40px;
          }

          @media (min-width: 1200px) {
            margin-top: 60px;
          }
        }
      }
    }
  }

  .after-section-group {
    position: relative;
    background-color: var(--purple-5);
    padding: 100px 0;
    min-height: 100vh;
    clip-path: circle(0% at 0 90%);

    @media (min-width: 1366px) {
      padding: 200px 0;
    }

    .after-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 25px;
      margin-bottom: 40px;

      @media (min-width: 768px) {
        margin-bottom: 60px;
      }

      @media (min-width: 1200px) {
        margin-bottom: 90px;
      }
    }

    .caption-text {
      color: var(--purple-2);
      margin-bottom: 10px;
      text-transform: uppercase;
      font-size: 16px;

      @media (min-width: 1024px) {
        font-size: 20px;
      }
    }

    .section-heading {
      font-size: 28px;
      letter-spacing: -0.2px;
      line-height: 120%;
      color: var(--purple-1);
      margin-bottom: 0px;

      @media (min-width: 768px) {
        font-size: 30px;
      }

      @media (min-width: 1024px) {
        font-size: 36px;
      }

      @media (min-width: 1366px) {
        font-size: 40px;
      }
    }

    ul {
      list-style-type: none;
      width: 100%;

      li {
        border-top: 1px solid var(--purple-4);
        padding-top: 10px;
        color: var(--purple-0);
        width: 100%;
        line-height: 1.35;
        font-size: 18px;

        @media (min-width: 768px) {
          font-size: 20px;
        }

        @media (min-width: 1024px) {
          font-size: 24px;
        }

        &:not(:first-child) {
          margin-top: 25px;

          @media (min-width: 1024px) {
            margin-top: 40px;
          }

          @media (min-width: 1200px) {
            margin-top: 60px;
          }
        }
      }
    }
  }

  .about-section-wrap {
    background: linear-gradient(
      to bottom,
      var(--purple-5) 50%,
      var(--purple-9) 100%
    );
    padding: 40px 0;

    @media (min-width: 768px) {
      padding: 60px 0;
    }

    @media (min-width: 1366px) {
      padding: 120px 0;
    }

    .about-content-main {
      max-width: 600px;
      margin: 0 auto;
    }

    .about-intro {
      margin-top: 30px;

      @media (min-width: 1366px) {
        margin-top: 60px;
      }
    }

    .about-intro p {
      font-size: 22px;
      line-height: 120%;
      margin-bottom: 30px;
      letter-spacing: -0.6px;

      @media (min-width: 1366px) {
        font-size: 30px;
        margin-bottom: 60px;
      }
    }

    .about-btn-ai {
      font-size: 22px;
      line-height: 1.2;
      color: var(--purple-0);
      margin-bottom: 60px;
      background-color: transparent;
      outline: none;
      box-shadow: none;
      border: none;
      text-decoration: underline;
      cursor: pointer;

      @media (min-width: 1366px) {
        font-size: 30px;
      }
    }

    .about-img {
      @media (min-width: 1366px) {
        padding-top: 60px;
      }
    }
  }

  /***************/
  .chat-bubble {
    padding: 10px 20px 14px 20px;
    border-radius: 15px;
    border-top-left-radius: 2px;
    border: 1px solid var(--purple-8);
    margin: 0 45px;
    background: linear-gradient(
      142.8deg,
      #ffffff -27.22%,
      #7510d8 49.94%,
      rgba(117, 16, 216, 0) 123.22%
    );

    @media (min-width: 1024px) {
      padding: 20px 30px 20px 40px;
      border-radius: 20px;
    }

    &:nth-of-type(2) {
      background: linear-gradient(
        164.77deg,
        #ffffff -108.18%,
        #7510d8 12.36%,
        rgba(117, 16, 216, 0) 126.84%
      );
    }

    &:nth-of-type(3) {
      background: linear-gradient(
        157.96deg,
        #ffffff -186.45%,
        #7510d8 -52.4%,
        rgba(117, 16, 216, 0) 74.9%
      );
    }

    .chat-message {
      font-size: 16px;
      max-width: 472px;
      font-weight: 400;
      line-height: 140%;

      @media (min-width: 768px) {
        font-size: 20px;
      }

      @media (min-width: 1200px) {
        font-size: 24px;
      }
    }
  }

  /***************/
  .about {
    min-height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--purple-9);

    .about-initial-message {
      position: relative;
      max-width: fit-content;
    }
  }

  .image-blob-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--image-blob-size);
    width: var(--image-blob-size);
    position: absolute;
    top: 0;
    left: 50px;

    img {
      user-select: none;
      pointer-events: none;
    }
  }

  .image-blob-icon {
    position: absolute;
    z-index: 1;
    height: 30px;
    pointer-events: none;
    transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    top: -22px;
    left: -35px;

    @media (min-width: 1024px) {
      height: 50px;
    }
  }

  .image-blob-glow {
    position: absolute;
    border-radius: 50%;
    will-change: transform;
    filter: blur(24px);
    user-select: none;
  }

  .image-blob {
    position: absolute;
    border-radius: 50%;
    will-change: transform;
    filter: blur(24px);
    user-select: none;
    height: 90px;
    width: 90px;

    @media (min-width: 1024px) {
      height: 150px;
      width: 150px;
    }
  }

  .image-blob-glow {
    height: 150px;
    width: 150px;
    mix-blend-mode: color-dodge;
    z-index: 1;
    transition: transform 1s ease;

    @media (min-width: 1024px) {
      height: 220px;
      width: 220px;
    }

    img {
      width: 100% !important;
      height: 100% !important;
      border-radius: 50% !important;
    }
  }

  .more-section {
    background: linear-gradient(to bottom, var(--purple-5), var(--purple-9));
    padding: 40px 0;

    @media (min-width: 768px) {
      padding: 60px 0;
    }

    @media (min-width: 1366px) {
      padding: 120px 0;
    }

    .content-wrapper {
      margin-top: 30px;
      padding-top: 20px;
      border-top: 1px solid var(--purple-5);
      font-size: 20px;
      line-height: 130%;

      @media (min-width: 1366px) {
        margin-top: 86px;
      }

      .more-title {
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.2px;

        @media (min-width: 1366px) {
          font-size: 30px;
        }
      }

      p {
        font-size: 16px;
        line-height: 150%;
        margin-top: 20px;

        @media (min-width: 1366px) {
          font-size: 20px;
        }
      }
    }
  }

  .datasecurity-section {
    background-image: url(../../public/assets/images/generative-ai/datasecurity-bg.png);
    background-size: cover;
    padding: 40px 0;

    @media (min-width: 768px) {
      padding: 60px 0;
    }

    @media (min-width: 1366px) {
      padding: 116px 0;
    }

    .overline {
      color: var(--purple-3);
    }

    .sub-info {
      margin-top: 25px;

      @media (min-width: 768px) {
        margin-top: 0px;
      }

      p {
        font-size: 18px;
        letter-spacing: -0.48px;
        line-height: 150%;
        margin-bottom: 15px;

        @media (min-width: 768px) {
          font-size: 20px;
          margin-bottom: 20px;
        }

        @media (min-width: 1024px) {
          font-size: 24px;
        }
      }
    }

    .info {
      margin-top: 20px;
    }

    .content-wrapper {
      padding: 30px;
      border-radius: 20px;
      position: relative;
      color: #fff;
      background-clip: padding-box;
      border: 1px solid transparent;
      cursor: default;

      @media (min-width: 1024px) {
        margin-top: 50px;
      }

      &:before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: -1px;
        border-radius: inherit;
        background: linear-gradient(
          35deg,
          var(--purple-0) 0%,
          var(--purple-3) 10%,
          var(--purple-5),
          var(--purple-7) 30%,
          var(--purple-9) 100%
        );
      }

      &::after {
        background: var(--purple-9);
        margin: 0;
        box-shadow: inset 50px 50px 100px -90px var(--purple-7),
          -50px -50px 200px 0 var(--purple-8);
        transition: all 3s var(--smooth-ease-1);
      }

      &:hover {
        &::after {
          box-shadow: inset 50px 50px 100px -20px var(--purple-6),
            -50px -50px 200px 0 var(--purple-5);
        }

        .icon {
          fill: var(--purple-1);
        }
      }

      @media (min-width: 768px) {
        height: 100%;
      }

      @media (min-width: 1366px) {
        min-height: 480px;
      }

      .icon {
        fill: var(--purple-3);
        margin-bottom: 30px;
        position: relative;
        z-index: 1;
        transition: all 3s var(--smooth-ease-1);

        @media (min-width: 1024px) {
          margin-bottom: 40px;
        }

        @media (min-width: 1366px) {
          margin-bottom: 60px;
        }
      }

      .sec-title {
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 20px;
        position: relative;
        z-index: 1;

        @media (min-width: 1024px) {
          font-size: 24px;
        }

        @media (min-width: 1024px) {
          font-size: 32px;
          margin-bottom: 60px;
        }
      }

      p {
        font-size: 18px;
        letter-spacing: -0.48px;
        line-height: 150%;
        margin-bottom: 15px;
        position: relative;
        z-index: 1;

        @media (min-width: 768px) {
          font-size: 20px;
          margin-bottom: 20px;
        }

        @media (min-width: 1024px) {
          font-size: 24px;
        }
      }
    }
  }

  .conversation-section {
    padding: 40px 0;

    @media (min-width: 768px) {
      padding: 60px 0;
    }

    @media (min-width: 1366px) {
      padding: 120px 0;
    }

    .heading-1 {
      margin-bottom: 30px;

      @media (min-width: 768px) {
        margin-bottom: 50px;
      }

      @media (min-width: 1366px) {
        margin-bottom: 100px;
      }

      span {
        color: var(--purple-2);
      }
    }

    .card-section {
      .card-data {
        margin-top: 20px;
      }

      .content-wrapper {
        border-radius: 20px;
        transition: all 1s var(--smooth-ease-1);

        @media (min-width: 768px) {
          height: 100%;
        }

        .highlight {
          inset: 0;
          opacity: 0;
          border-radius: 20px;
          transition: opacity 0.4s ease-out;
        }

        &:hover {
          transform: scale(1.025);
          z-index: 1;

          .highlight {
            opacity: 1;
          }
        }

        .inner-padding {
          padding: 20px;
        }

        &.pattern-one {
          --accent: var(--purple-7);
          background-color: var(--purple-8);
        }

        &.pattern-two {
          --accent: var(--purple-6);
          background-color: var(--purple-7);
        }

        &.pattern-three {
          --accent: var(--purple-7);
          background-color: var(--purple-6);
        }

        &.pattern-four {
          --accent: var(--purple-6);
          background-color: var(--purple-5);
        }

        .sec-title {
          font-size: 22px;
          line-height: 120%;
          letter-spacing: -0.2px;
          margin-bottom: 20px;

          @media (min-width: 1366px) {
            font-size: 30px;
            margin-bottom: 40px;
          }
        }

        .icon {
          fill: var(--purple-3);
        }

        .sub-info {
          font-size: 16px;
          line-height: 140%;
          margin-bottom: 30px;

          @media (min-width: 768px) {
            font-size: 20px;
          }

          @media (min-width: 1366px) {
            margin-bottom: 60px;
          }
        }

        .link {
          border-top: 1px solid rgba(228, 208, 255, 0.5);

          a {
            color: #fff5ff;
            font-size: 16px;
            line-height: 140%;
            width: 100%;
            display: flex;
            height: 100%;
            padding: 20px;
            transition: background-color 0.2s ease-out;

            &:hover,
            &:focus-visible {
              background-color: rgba(0, 0, 0, 0.2);
            }

            &:active {
              background-color: rgba(0, 0, 0, 0.3);
            }

            @media (min-width: 768px) {
              font-size: 20px;
            }
          }
        }

        .qr-section {
          border-top: 1px solid rgba(228, 208, 255, 0.5);
          font-size: 16px;
          line-height: 140%;

          @media (min-width: 768px) {
            font-size: 20px;
          }

          img {
            max-width: 100px;
            mix-blend-mode: screen;
          }
        }
      }
    }
  }

  .teamup-section {
    background-image: url(../../public/assets/images/generative-ai/datasecurity-bg.png);
    background-size: cover;
    padding: 60px 0;

    @media (min-width: 1366px) {
      padding: 120px 0 0 0;
    }

    svg {
      margin: 0 auto;
      margin-bottom: -3px;
      width: 100%;
    }

    .container {
      position: relative;
    }

    .heading-1 {
      max-width: 610px;

      @media (min-width: 768px) {
        position: absolute;
        top: 0;
      }
    }

    .points {
      margin-top: 40px;
      padding-left: 30px;
      margin-left: 10px;
      position: relative;
      --height: 10%;

      &:before {
        content: '';
        position: absolute;
        left: -5px;
        width: 5px;
        height: var(--height);
        top: 0;
        bottom: 0;
        border-radius: 500px;
        background: linear-gradient(
          to bottom,
          var(--purple-1),
          var(--purple-2) 5%,
          var(--purple-3) 10%,
          var(--purple-7) 100%
        );
        box-shadow: 10px 0px 100px 50px var(--purple-7);
      }

      .points-list {
        .points-item {
          position: relative;

          &:not(:first-child) {
            margin-top: 60px;
          }
        }
      }

      .sec-title {
        color: var(--purple-3);
        font-size: 18px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      p {
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.48px;
        margin-top: 8px;
      }
    }
  }

  .quote-section {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 100px 0 40px 0;

    @media (min-width: 768px) {
      padding: 60px 0;
    }

    @media (min-width: 1366px) {
      padding: 120px 0;
    }

    .quote-data {
      max-width: 630px;
      margin: 0 auto;

      .frm-group {
        margin-bottom: 30px;
      }

      input,
      textarea {
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(255, 245, 255, 0.2);
        background: rgba(255, 255, 255, 0.1);
        font-size: 18px;
        padding: 10px 20px;
        color: #fff !important;
        transition: all 0.1s ease-out;

        @media (min-width: 1024px) {
          font-size: 20px;
        }

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
          letter-spacing: -0.4px;
        }

        &:focus-visible {
          background-color: transparent;
          border-color: #fff5ff;
          outline: none;
        }
      }

      .submit-btn {
        border-radius: 50px !important;
        border: 1px solid rgba(255, 245, 255, 0.2) !important;
        background: #fff !important;
        font-size: 20px !important;
        padding: 15px 27px !important;
        color: #050415 !important;
        transition: all 0.1s ease;

        &.loading {
          background-color: #050415 !important;
          color: #fff !important;
        }

        &:before {
          display: none !important;
        }

        &:hover {
          background-color: #e4d0ff !important;
        }
      }

      .alert-error {
        padding: 5px 10px;
        font-size: 15px !important;
      }

      .PhoneInput {
        border-radius: 10px;
        border: 1px solid rgba(255, 245, 255, 0.2);
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(50px);
        padding: 10px 20px;

        &:focus-within {
          background-color: transparent;
          border-color: #fff5ff;
          outline: none;
        }
      }
      .PhoneInputInput {
        height: auto;
        border: none;
        background-color: transparent;
        padding: 0 10px;
        backdrop-filter: none;
      }
    }

    .about-initial-message {
      position: relative;
      max-width: fit-content;
      margin-bottom: 100px;
    }
  }

  .scroll-btn {
    border-radius: 30px;
    border: 1px solid rgba(255, 245, 255, 0.2);
    background: #fff;
    font-size: 18px;
    padding: 10px 20px;
    color: #050415;
    display: inline-block;
    text-decoration: none;
    margin-top: 20px;
    margin-bottom: 20px;
    user-select: none;

    @media (min-width: 1024px) {
      font-size: 20px;
      padding: 15px 27px;
      margin-top: 40px;
    }

    &:hover {
      background-color: #e4d0ff;
    }
  }

  & + footer {
    margin-top: 0;
    background-color: #020012;
  }

  .container {
    @media (max-width: 575px) {
      padding: 0 30px;
    }
  }

  /*******Header Menu AI ***********/
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #000;
    border-radius: 100px;
    margin-top: 40px;
    height: 80px;
    padding: 10px;
    position: sticky;
    z-index: 9;
    max-width: 1600px;
    margin: 0 auto;
    width: calc(100% - 30px);
    z-index: 99;

    @media only screen and (max-width: 991px) {
      top: 10px;
      height: 60px;
    }

    @media only screen and (max-width: 475px) {
      top: 5px;
    }

    .logo {
      margin-left: 20px;

      @media only screen and (max-width: 991px) {
        margin-left: 5px;
        padding-top: 8px;
      }

      img {
        filter: grayscale(1) brightness(10);
      }
    }

    .nav_wrap {
      @media only screen and (max-width: 991px) {
        width: 100%;
      }
    }
    .navbar {
      width: 100%;

      .container {
        padding-top: 12px;
        padding-bottom: 12px;

        .navbar-brand {
          img {
            height: 64px;

            @media only screen and (max-width: 991px) {
              height: 54px;
            }
          }
        }

        .navbar-toggler-icon {
          filter: invert(1) brightness(5);
        }

        .navbar-collapse {
          flex-grow: unset;
          margin-right: -10px;

          @media only screen and (max-width: 991px) {
            // position: absolute;
            right: 10px;
            top: 60px;
            padding: 20px;
            border-radius: 0px;
            background: rgba(33, 32, 32, 0.9);
            z-index: 9;
            margin-right: 0;
            width: 100%;
          }
        }

        .navbar-toggler {
          border: none;
          padding: 0;
        }

        .navbar-nav {
          align-items: center;
          @media only screen and (max-width: 991px) {
            align-items: flex-start;
          }
          a.nav-link {
            font-size: 18px;
            font-weight: 400;
            margin-right: 50px;
            margin-right: calc(
              30px + (50 - 30) * ((100vw - 992px) / (1920 - 992))
            );
            color: #fff;

            @media only screen and (max-width: 991px) {
              margin-right: 0;
              width: 100%;
              font-size: 32px;
              margin: 0px;
            }

            &:hover,
            &.current {
              color: #ccc;
            }
          }
        }

        .right_links_wrap {
          margin-left: auto;

          @media only screen and (max-width: 991px) {
            margin-top: 0px;
            margin-left: 0px;
          }

          .right_links {
            position: relative;

            a {
              border-radius: 50px;
              border: 1px solid rgba(255, 245, 255, 0.2);
              background: #fff;
              -webkit-backdrop-filter: blur(50px);
              backdrop-filter: blur(50px);
              font-size: 18px;
              padding: 10px 20px;
              color: #050415;
              display: inline-block;
              text-decoration: none;
              margin-top: 20px;
              margin-bottom: 20px;
              transition: all 0.3s ease;

              @media only screen and (max-width: 991px) {
                font-size: 32px;
                width: 100%;
                background: transparent;
                border: none;
                border-radius: 0px;
                backdrop-filter: blur(0px);
                padding: 0.5rem 0;
                color: #fff;
                margin: 0;
              }
            }
          }
        }
      }
    }

    .menus {
      nav {
        ul {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          list-style: none;

          li {
            margin-right: 50px;

            a {
              color: #fff;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.gen_ai_main {
  background-color: #000;
}

.alert-error:empty, .alert-successfully:empty {
  padding: 0 !important;
  margin: 0 !important;
}
/*!
 * Hover.css (http://ianlunn.github.io/Hover/)
 * Version: 2.3.2
 * Author: Ian Lunn @IanLunn
 * Author URL: http://ianlunn.co.uk/
 * Github: https://github.com/IanLunn/Hover

 * Hover.css Copyright Ian Lunn 2017. Generated with Sass.
 */

@import "options";
@import "mixins";
@import "hacks";


/* 2D TRANSITIONS */
@import "effects/2d-transitions/grow";

@if $includeClasses {
	.#{$nameSpace}-grow {
		@include grow();
	}
}

@import "effects/2d-transitions/shrink";

@if $includeClasses {
	.#{$nameSpace}-shrink {
		@include shrink();
	}
}

@import "effects/2d-transitions/pulse";

@if $includeClasses {
	.#{$nameSpace}-pulse {
		@include pulse();
	}
}

@import "effects/2d-transitions/pulse-grow";

@if $includeClasses {
	.#{$nameSpace}-pulse-grow {
		@include pulse-grow();
	}
}

@import "effects/2d-transitions/pulse-shrink";

@if $includeClasses {
	.#{$nameSpace}-pulse-shrink {
		@include pulse-shrink();
	}
}

@import "effects/2d-transitions/push";

@if $includeClasses {
	.#{$nameSpace}-push {
		@include push();
	}
}

@import "effects/2d-transitions/pop";

@if $includeClasses {
	.#{$nameSpace}-pop {
		@include pop();
	}
}

@import "effects/2d-transitions/bounce-in";

@if $includeClasses {
	.#{$nameSpace}-bounce-in {
		@include bounce-in();
	}
}

@import "effects/2d-transitions/bounce-out";

@if $includeClasses {
	.#{$nameSpace}-bounce-out {
		@include bounce-out();
	}
}

@import "effects/2d-transitions/rotate";

@if $includeClasses {
	.#{$nameSpace}-rotate {
		@include rotate();
	}
}

@import "effects/2d-transitions/grow-rotate";

@if $includeClasses {
	.#{$nameSpace}-grow-rotate {
		@include grow-rotate();
	}
}

@import "effects/2d-transitions/float";

@if $includeClasses {
	.#{$nameSpace}-float {
		@include float();
	}
}

@import "effects/2d-transitions/sink";

@if $includeClasses {
	.#{$nameSpace}-sink {
		@include sink();
	}
}

@import "effects/2d-transitions/bob";

@if $includeClasses {
	.#{$nameSpace}-bob {
		@include bob();
	}
}

@import "effects/2d-transitions/hang";

@if $includeClasses {
	.#{$nameSpace}-hang {
		@include hang();
	}
}

@import "effects/2d-transitions/skew";

@if $includeClasses {
	.#{$nameSpace}-skew {
		@include skew();
	}
}

@import "effects/2d-transitions/skew-forward";

@if $includeClasses {
	.#{$nameSpace}-skew-forward {
		@include skew-forward();
	}
}

@import "effects/2d-transitions/skew-backward";

@if $includeClasses {
	.#{$nameSpace}-skew-backward {
		@include skew-backward();
	}
}

@import "effects/2d-transitions/wobble-vertical";

@if $includeClasses {
	.#{$nameSpace}-wobble-vertical {
		@include wobble-vertical();
	}
}

@import "effects/2d-transitions/wobble-horizontal";

@if $includeClasses {
	.#{$nameSpace}-wobble-horizontal {
		@include wobble-horizontal();
	}
}

@import "effects/2d-transitions/wobble-to-bottom-right";

@if $includeClasses {
	.#{$nameSpace}-wobble-to-bottom-right {
		@include wobble-to-bottom-right();
	}
}

@import "effects/2d-transitions/wobble-to-top-right";

@if $includeClasses {
	.#{$nameSpace}-wobble-to-top-right {
		@include wobble-to-top-right();
	}
}

@import "effects/2d-transitions/wobble-top";

@if $includeClasses {
	.#{$nameSpace}-wobble-top {
		@include wobble-top();
	}
}

@import "effects/2d-transitions/wobble-bottom";

@if $includeClasses {
	.#{$nameSpace}-wobble-bottom {
		@include wobble-bottom();
	}
}

@import "effects/2d-transitions/wobble-skew";

@if $includeClasses {
	.#{$nameSpace}-wobble-skew {
		@include wobble-skew();
	}
}

@import "effects/2d-transitions/buzz";

@if $includeClasses {
	.#{$nameSpace}-buzz {
		@include buzz();
	}
}

@import "effects/2d-transitions/buzz-out";

@if $includeClasses {
	.#{$nameSpace}-buzz-out {
		@include buzz-out();
	}
}

@import "effects/2d-transitions/forward";

@if $includeClasses {
	.#{$nameSpace}-forward {
		@include forward();
	}
}

@import "effects/2d-transitions/backward";

@if $includeClasses {
	.#{$nameSpace}-backward {
		@include backward();
	}
}




/* ICONS */
@import "effects/icons/icon-back";

@if $includeClasses {
	.#{$nameSpace}-icon-back {
		@include icon-back();
	}
}

@import "effects/icons/icon-forward";

@if $includeClasses {
	.#{$nameSpace}-icon-forward {
		@include icon-forward();
	}
}

@import "effects/icons/icon-down";

@if $includeClasses {
	.#{$nameSpace}-icon-down {
		@include icon-down();
	}
}

@import "effects/icons/icon-up";

@if $includeClasses {
	.#{$nameSpace}-icon-up {
		@include icon-up();
	}
}

@import "effects/icons/icon-spin";

@if $includeClasses {
	.#{$nameSpace}-icon-spin {
		@include icon-spin();
	}
}

@import "effects/icons/icon-drop";

@if $includeClasses {
	.#{$nameSpace}-icon-drop {
		@include icon-drop();
	}
}

@import "effects/icons/icon-fade";

@if $includeClasses {
	.#{$nameSpace}-icon-fade {
		@include icon-fade();
	}
}

@import "effects/icons/icon-float-away";

@if $includeClasses {
	.#{$nameSpace}-icon-float-away {
		@include icon-float-away();
	}
}

@import "effects/icons/icon-sink-away";

@if $includeClasses {
	.#{$nameSpace}-icon-sink-away {
		@include icon-sink-away();
	}
}

@import "effects/icons/icon-grow";

@if $includeClasses {
	.#{$nameSpace}-icon-grow {
		@include icon-grow();
	}
}

@import "effects/icons/icon-shrink";

@if $includeClasses {
	.#{$nameSpace}-icon-shrink {
		@include icon-shrink();
	}
}

@import "effects/icons/icon-pulse";

@if $includeClasses {
	.#{$nameSpace}-icon-pulse {
		@include icon-pulse();
	}
}

@import "effects/icons/icon-pulse-grow";

@if $includeClasses {
	.#{$nameSpace}-icon-pulse-grow {
		@include icon-pulse-grow();
	}
}

@import "effects/icons/icon-pulse-shrink";

@if $includeClasses {
	.#{$nameSpace}-icon-pulse-shrink {
		@include icon-pulse-shrink();
	}
}

@import "effects/icons/icon-push";

@if $includeClasses {
	.#{$nameSpace}-icon-push {
		@include icon-push();
	}
}

@import "effects/icons/icon-pop";

@if $includeClasses {
	.#{$nameSpace}-icon-pop {
		@include icon-pop();
	}
}

@import "effects/icons/icon-bounce";

@if $includeClasses {
	.#{$nameSpace}-icon-bounce {
		@include icon-bounce();
	}
}

@import "effects/icons/icon-rotate";

@if $includeClasses {
	.#{$nameSpace}-icon-rotate {
		@include icon-rotate();
	}
}

@import "effects/icons/icon-grow-rotate";

@if $includeClasses {
	.#{$nameSpace}-icon-grow-rotate {
		@include icon-grow-rotate();
	}
}

@import "effects/icons/icon-float";

@if $includeClasses {
	.#{$nameSpace}-icon-float {
		@include icon-float();
	}
}

@import "effects/icons/icon-sink";

@if $includeClasses {
	.#{$nameSpace}-icon-sink {
		@include icon-sink();
	}
}

@import "effects/icons/icon-bob";

@if $includeClasses {
	.#{$nameSpace}-icon-bob {
		@include icon-bob();
	}
}

@import "effects/icons/icon-hang";

@if $includeClasses {
	.#{$nameSpace}-icon-hang {
		@include icon-hang();
	}
}

@import "effects/icons/icon-wobble-horizontal";

@if $includeClasses {
	.#{$nameSpace}-icon-wobble-horizontal {
		@include icon-wobble-horizontal();
	}
}

@import "effects/icons/icon-wobble-vertical";

@if $includeClasses {
	.#{$nameSpace}-icon-wobble-vertical {
		@include icon-wobble-vertical();
	}
}

@import "effects/icons/icon-buzz";

@if $includeClasses {
	.#{$nameSpace}-icon-buzz {
		@include icon-buzz();
	}
}

@import "effects/icons/icon-buzz-out";

@if $includeClasses {
	.#{$nameSpace}-icon-buzz-out {
		@include icon-buzz-out();
	}
}